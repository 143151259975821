import request from '../helpers/request';

export const authLogin = (login, password) => {
  return request({
    url: '/users/token/create/identity',
    method: 'POST',
    data: {
      login,
      password
    }
  });
};
export const authRefresh = token => {
  return request({
    url: '/users/token/refresh',
    method: 'POST',
    data: {
      token
    }
  });
};
export const getCashierById = (id, token) => {
  return request({
    url: `/users/cashiers?user_id=${id}`,
    method: 'GET'
    // headers: {
    //   Authorization: `Bearer ${token}`
    // }
  });
};
export const getAllCashiers = () => {
  return request({
    url: '/users/cashiers',
    method: 'GET'
  });
};
export const getUserRole = token => {
  return request({
    url: '/users/roles',
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
export const enableCredit = user_id => {
  const bodyFormData = new FormData();
  bodyFormData.set('user_id', user_id);
  return request({
    url: '/users/cashiers/enable_credit',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: bodyFormData
  });
};
export const disableCredit = user_id => {
  const bodyFormData = new FormData();
  bodyFormData.set('user_id', user_id);
  return request({
    url: '/users/cashiers/disable_credit',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: bodyFormData
  });
};
export const increaseCashierCredit = (user_id, amount) => {
  const bodyFormData = new FormData();
  bodyFormData.set('user_id', user_id);
  bodyFormData.set('amount', amount);
  return request({
    url: '/users/cashiers/credit',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: bodyFormData
  });
};
export const decreaseCashierCredit = (user_id, amount) => {
  const bodyFormData = new FormData();
  bodyFormData.set('user_id', user_id);
  bodyFormData.set('amount', amount);
  return request({
    url: '/users/cashiers/debit',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: bodyFormData
  });
};
export const getCashierLoadUnloadTransacations = (site_id, cashier_id, from, to) => {
  let queryParams = `?from=${from}&to=${to}`;
  if (site_id) {
    queryParams += `&site_id=${site_id}`;
  }
  if (cashier_id) {
    queryParams += `&cashier_id=${cashier_id}`;
  }

  return request({
    url: `/transactions/load_unload${queryParams}`,
    method: 'GET'
  });
};
export const getCashierCreditTransactions = (cashier_id, from, to) => {
  let queryParams = `?from=${from}&to=${to}`;
  if (cashier_id) {
    queryParams += `&cashier_id=${cashier_id}`;
  }
  return request({
    url: `/transactions/cashier_credit${queryParams}`,
    method: 'GET'
  });
};
