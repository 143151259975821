import React, { useContext, memo } from 'react';
import Tab from './Tab';
import imageManager from '../../../media/imageManager';
import { stateContext } from '../../../context/appContext';
import { OPERATOR_ROLE } from '../../../consts';

function Tabs({ activeTab, onChangeActiveTab }) {
  const {
    user: { role: userRole }
  } = useContext(stateContext);

  return (
    <div className='header-tabs mobile'>
      <Tab
        value='history'
        icon={imageManager.archive}
        active={activeTab === 'history'}
        clickHandler={onChangeActiveTab}
      />
      {userRole === OPERATOR_ROLE && (
        <Tab
          value='credit'
          icon={imageManager.money}
          active={activeTab === 'credit'}
          clickHandler={onChangeActiveTab}
        />
      )}
      <div className='header-tabs-substrate' />
    </div>
  );
}

export default memo(Tabs);
