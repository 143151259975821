import React, { memo, useContext } from 'react';
import { stateContext } from '../../../context/appContext';
import HeaderContent from '../../../components/Header';
import Label from './Label';
import { TYPE_UNLOAD_FUNDS, TYPE_LOAD_FUNDS } from '../../../consts';
import Select from '../../../components/Select';
import { CASHIER_ROLE, OPERATOR_ROLE } from '../../../consts';

function Header({ handleChangeSite }) {
  const { user, siteInfo } = useContext(stateContext);
  return (
    <div className='header-container'>
      <HeaderContent hasBalance={true}>
        {user.role === CASHIER_ROLE ? (
          <>
            {siteInfo.id} - {siteInfo.name}
          </>
        ) : user.role === OPERATOR_ROLE && user.sites.length === 1 ? (
          <>
            {user.sites[0].id} - {user.sites[0].name}
          </>
        ) : (
          <Select
            handleChange={handleChangeSite}
            value={{ id: siteInfo.id, name: siteInfo.name }}
            options={user.sites}
          />
        )}
      </HeaderContent>
      <div className='labels'>
        <Label value='In' type={TYPE_LOAD_FUNDS} />
        <Label value='Out' type={TYPE_UNLOAD_FUNDS} />
      </div>
    </div>
  );
}

export default memo(Header);
