import React, { memo } from 'react';

function FilterCurrent({ currentValues }) {
  return (
    <div className='settings-panel-current'>
      {currentValues && currentValues.map(value => <div key={value}>{value}</div>)}
    </div>
  );
}

export default memo(FilterCurrent);
