import React, { useContext, memo } from 'react';
import imageManager from '../../media/imageManager';
import { stateContext } from '../../context/appContext';
import currencyFormatter from '../../helpers/currencyFormatter';

function BalanceLabel() {
  const { cashierCredit, has_credit } = useContext(stateContext);
  if (has_credit) {
    return (
      <div className='header-content-balance'>
        <img src={imageManager.balance} alt='logo' />
        <div className='information-credit column'>
          <span>Credit Remaining</span>
          <span className='credit-value'>{currencyFormatter(cashierCredit)}</span>
        </div>
      </div>
    );
  }
  return null;
}

export default memo(BalanceLabel);
