import React from 'react';
const displayIcon = (name, theme) => {
  const _name = name.toLowerCase();
  switch (_name) {
    case 'phone':
      return (
        <svg
          className={`button-icon ${theme}`}
          xmlns='http://www.w3.org/2000/svg'
          width='64.606'
          height='110'
          viewBox='0 0 64.606 110.753'
        >
          <path
            id='iconmonstr-smartphone-3'
            d='M69.606 9.229A9.233 9.233 0 0 0 60.377 0H14.229A9.233 9.233 0 0 0 5 9.229v92.295a9.233 9.233 0 0 0 9.229 9.229h46.148a9.233 9.233 0 0 0 9.229-9.229zm-39.225 0h13.844a2.307 2.307 0 1 1 0 4.615H30.381a2.307 2.307 0 1 1 0-4.615zm6.919 92.295a4.615 4.615 0 1 1 4.61-4.615 4.613 4.613 0 0 1-4.61 4.615zM60.377 87.68H14.229V22.963h46.148z'
            transform='translate(-5)'
          />
        </svg>
      );
    case 'unit':
      return (
        <svg
          className={`button-icon ${theme}`}
          xmlns='http://www.w3.org/2000/svg'
          width='110'
          height='110'
          viewBox='0 0 110.386 110.386'
        >
          <path
            id='iconmonstr-computer-1'
            d='M0 0v87.389h110.386V0zm101.187 78.19H9.2V9.2h91.988zm-30.351 18.4l11.954 13.8H27.6l11.954-13.8z'
          />
        </svg>
      );
    case 'token':
      return (
        <svg
          className={`button-icon ${theme}`}
          xmlns='http://www.w3.org/2000/svg'
          width='110'
          height='110'
          viewBox='0 0 110.21 110.276'
        >
          <g id='_000000ff' data-name='#000000ff' transform='translate(-75.61 -82.86)'>
            <path
              id='Path_993'
              d='M141.811 85.652a18.038 18.038 0 0 1 3.071-2.792c4.632 4.543 9.17 9.181 13.787 13.738a20.243 20.243 0 0 1 2.872 3.125c-.723.772-1.532 1.457-2.253 2.231a5.561 5.561 0 0 0-1.41 3.285 5.335 5.335 0 0 0 3.895 5.392 5.608 5.608 0 0 0 5.385-1.511 24.831 24.831 0 0 1 1.936-1.882 10.39 10.39 0 0 1 1.776 1.558c1.711 1.809 3.562 3.48 5.275 5.289 2.492 2.508 5 5 7.5 7.508a16.883 16.883 0 0 1 2.173 2.332 26.254 26.254 0 0 1-2.008 2.079q-32.729 32.739-65.464 65.467a15.209 15.209 0 0 1-1.748 1.664q-7.444-7.472-14.911-14.921a19.36 19.36 0 0 1-1.706-1.75c-.023-.263.26-.415.394-.6a11.055 11.055 0 0 0 2.773-3.33 5.447 5.447 0 0 0-.765-5.338 4.892 4.892 0 0 0-3.442-1.959 5.566 5.566 0 0 0-3.083.46c-1.394.765-2.264 2.159-3.5 3.128-.727-.617-1.389-1.3-2.053-1.987l-12.668-12.674a11.544 11.544 0 0 1-2.027-2.253c3.383-3.538 6.948-6.9 10.355-10.42 1.861-1.9 3.808-3.7 5.643-5.622q1.964-2.024 4.008-3.965c2.264-2.274 4.479-4.594 6.786-6.823 1.837-1.76 3.564-3.632 5.387-5.408 2.576-2.429 4.972-5.04 7.511-7.506 2.358-2.255 4.585-4.644 6.922-6.922 2.438-2.33 4.73-4.808 7.159-7.149 1.964-1.87 3.8-3.869 5.746-5.756 2.3-2.159 4.428-4.489 6.685-6.687m2.942 5.96q-29.139 29.181-58.315 58.331a29.578 29.578 0 0 0-2.1 2.168c.936 1.11 2.034 2.065 3.039 3.109 1.76 1.739 3.482 3.515 5.251 5.244 2.405-1.176 5.193-1.966 7.849-1.215a11.688 11.688 0 0 1 7.933 5.913 11.294 11.294 0 0 1 1.161 7.2 16.872 16.872 0 0 1-1.258 3.7 30.728 30.728 0 0 0 2.412 2.5c1.75 1.769 3.538 3.506 5.265 5.3.228.176.415.488.723.514a17.471 17.471 0 0 0 1.638-1.591q28.445-28.452 56.9-56.9c.648-.622 1.286-1.251 1.87-1.936-.906-1.065-1.957-1.99-2.926-2.992-1.8-1.788-3.576-3.6-5.385-5.383a12.627 12.627 0 0 1-3.949 1.309 24.541 24.541 0 0 1-3.271 0 11.505 11.505 0 0 1-8.463-16.9 15.37 15.37 0 0 0-.976-1.093c-1.708-1.654-3.367-3.36-5.056-5.031a27.5 27.5 0 0 0-2.343-2.248z'
              data-name='Path 993'
            />
            <path
              id='Path_994'
              d='M294.812 206.9c1.065-.971 1.99-2.084 3.1-3.006.542.49 1.063 1 1.565 1.535a23.491 23.491 0 0 1 2.734 2.942 20.712 20.712 0 0 1-1.516 1.6c-.934.882-1.772 1.863-2.762 2.684q-.771-.662-1.474-1.391c-.929-.99-1.964-1.879-2.839-2.921a6.331 6.331 0 0 1 1.192-1.443z'
              data-name='Path 994'
              transform='translate(-166.856 -92.632)'
            />
            <path
              id='Path_995'
              d='M327.156 235.574c.34-.289.69-.748 1.192-.638a6.192 6.192 0 0 1 1.143 1.032c1.016 1.105 2.138 2.107 3.172 3.193-.92.967-1.912 1.863-2.827 2.837a10.424 10.424 0 0 1-1.654 1.443c-1.042-.856-1.91-1.891-2.893-2.809a8.5 8.5 0 0 1-1.359-1.5c.07-.46.474-.77.767-1.1.84-.799 1.65-1.632 2.459-2.458z'
              data-name='Path 995'
              transform='translate(-190.054 -116.38)'
            />
            <path
              id='Path_996'
              d='M359.436 266.08a12.828 12.828 0 0 1 2.276 2.079 18.072 18.072 0 0 1 2.18 2.325 20.562 20.562 0 0 1-1.542 1.624c-.936.88-1.772 1.863-2.762 2.682-1.117-.929-2.051-2.046-3.116-3.032a11.332 11.332 0 0 1-1.3-1.4c1.42-1.427 2.849-2.847 4.264-4.278z'
              data-name='Path 996'
              transform='translate(-213.964 -140.229)'
            />
            <path
              id='Path_997'
              d='M391.423 298.09a7.466 7.466 0 0 1 1.476 1.241c.955 1.042 2.027 1.969 2.971 3.022a5.849 5.849 0 0 1-1.169 1.417c-1.068.981-2.011 2.093-3.121 3.032-1.112-.936-2.053-2.048-3.116-3.036a11.209 11.209 0 0 1-1.3-1.406c1.418-1.421 2.847-2.836 4.259-4.27z'
              data-name='Path 997'
              transform='translate(-238.448 -164.729)'
            />
          </g>
        </svg>
      );
    default:
      break;
  }
};

export default displayIcon;
