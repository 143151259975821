import React, { useReducer, useEffect, useRef } from 'react';
import { initialState, State, Dispatch, SocketContext } from './appContext';
import reducer from './reducer';
import {
  CASHIER_ROLE,
  OPERATOR_ROLE,
  TYPE_UNLOAD_FUNDS,
  TYPE_LOAD_FUNDS,
  MAX_COUNT_RECCONECT
} from '../consts';
import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  ERROR_NULL,
  IN_FILTERED_VALUE,
  OUT_FILTERED_VALUE,
  PRESS_BUTTON,
  SET_OPERATION_TYPE,
  OPEN_MODAL_WINDOW,
  CLOSE_MODAL_WINDOW,
  CHANGE_MODAL_PARAM,
  CHANGE_MODAL_VALUE,
  SET_INFO_MESSAGE,
  SET_TO_MOBILE,
  SET_TO_WEB,
  LOGOUT,
  OPEN_INFO_MODAL,
  TRANSACTION_ERROR,
  CLOSE_INFO_MODAL,
  SHOW_INCREASE_CREDIT_MODAL,
  CLOSE_INCREASE_CREDIT_MODAL,
  SHOW_DECREASE_CREDIT_MODAL,
  CLOSE_DECREASE_CREDIT_MODAL,
  SET_CASHIER_CREDIT,
  SET_TRANSACTIONS,
  UPDATE_TRANSACTIONS,
  SET_SITE_INFO,
  SET_STATUS_CHANNEL,
  SET_FETCHING_STATUS,
  SHOW_CONFIRM_MODAL,
  CLOSE_CONFIRM_MODAL
} from './actionTypes';
import { withRouter } from 'react-router-dom';
import { authLogin, authRefresh, getUserRole } from '../providers';
import Preloader from '../components/Preloader';
import Socket from '../helpers/Socket';
import { ToastContainer } from 'react-toastify';
import { sortById } from '../helpers/sites';

const Store = ({ children, ...props }) => {
  let socket = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  let countRecconect = 0;
  const connect = async (token, isReconnect = false) => {
    const socket = await Socket.getInstance(
      `wss://${process.env.REACT_APP_API_URL}/pos/socket`,
      {
        params: {
          token
        }
      },
      isReconnect
    );
    socket.onError(() => {
      countRecconect++;
      if (countRecconect <= MAX_COUNT_RECCONECT) {
        setStatusChannel(false);
        checkAuth();
      } else {
        logout();
        window.location.href = '/login';
      }
    });
    return socket;
  };
  const login = async (username, password) => {
    try {
      setFetchingStatus(true);
      const profile = await authLogin(username, password);
      const user = await getUserRole(profile.access.token);

      socket.current = await connect(
        profile.access.token,
        true
      );

      let role, sites;
      switch (true) {
        case CASHIER_ROLE in user.all_sites && OPERATOR_ROLE in user.all_sites:
          role = OPERATOR_ROLE;
          sites = sortById([...user.all_sites[CASHIER_ROLE], ...user.all_sites[OPERATOR_ROLE]]);
          break;
        case CASHIER_ROLE in user.all_sites:
          role = CASHIER_ROLE;
          sites = sortById(user.all_sites[CASHIER_ROLE]);
          break;
        case OPERATOR_ROLE in user.all_sites:
          role = OPERATOR_ROLE;
          sites = sortById(user.all_sites[OPERATOR_ROLE]);
          break;
        default:
          // eslint-disable-next-line no-throw-literal
          throw { msg: 'Role not established' };
      }
      const site_id = sites[0].id;
      const site_name = sites[0].name;
      const site_info = { id: site_id, name: site_name };
      const payload = { ...profile, ...profile.user, role, sites };
      localStorage.setItem('profile', JSON.stringify(payload));
      localStorage.setItem('site_info', JSON.stringify(site_info));
      authSuccess(payload, site_info);
      resizeApp();
      props.history.push('/');
    } catch (error) {
      setFetchingStatus(false);
      let errMessage = error.msg;
      if (error.code === 'auth:unauthenticated') {
        errMessage = 'The username or password is incorrect';
      }
      dispatch({ type: LOGIN_ERROR, payload: errMessage });
    }
  };

  const logout = () => {
    if (socket.current) {
      socket.current.disconnect();
    }
    setFetchingStatus(false);
    dispatch({ type: LOGOUT });
    window.localStorage.removeItem('profile');
    props.history.push('/login');
  };

  const checkAuth = async () => {
    try {
      setFetchingStatus(true);
      const profile = window.localStorage.getItem('profile');
      if (profile) {
        const data = JSON.parse(profile);
        const site_info = JSON.parse(window.localStorage.getItem('site_info'));
        if (Date.now() < data.access.expires * 1000) {
          socket.current = await connect(
            data.access.token,
            true
          );
          authSuccess(data, site_info);
        } else {
          if (Date.now() < data.refresh.expires * 1000) {
            const response = await authRefresh(data.refresh.token);
            socket.current = await connect(
              response.access.token,
              true
            );
            const newData = { ...data, ...response };
            localStorage.setItem('profile', JSON.stringify(newData));
            authSuccess(newData, site_info);
          } else {
            logout();
          }
        }
        return;
      }
      setFetchingStatus(false);
    } catch {
      logout();
    }
  };

  const authSuccess = (payload, site_info) => {
    dispatch({ type: LOGIN_SUCCESS, payload });
    dispatch({ type: SET_SITE_INFO, payload: site_info });
    setFetchingStatus(false);
  };

  const setFetchingStatus = status => {
    dispatch({ type: SET_FETCHING_STATUS, payload: status });
  };

  function isMobile() {
    try {
      return window.innerWidth <= 1024;
    } catch (e) {
      return false;
    }
  }
  const resizeApp = () => {
    if (isMobile()) {
      setMobileSize();
    }
    if (!isMobile()) {
      setWebSize();
    }
  };
  useEffect(() => {
    resizeApp();
    window.addEventListener('resize', resizeApp);
    checkAuth();
    return () => window.removeEventListener('resize', resizeApp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setError = () => {
    return dispatch({ type: ERROR_NULL });
  };

  const setFilterValue = (value, type) => {
    if (type === TYPE_LOAD_FUNDS) {
      dispatch({ type: IN_FILTERED_VALUE, payload: value });
    } else {
      dispatch({ type: OUT_FILTERED_VALUE, payload: value });
    }
  };

  const handleButtonCLick = (buttonName, operationType) => {
    dispatch({ type: PRESS_BUTTON, payload: buttonName });
    dispatch({ type: SET_OPERATION_TYPE, payload: operationType });
    openModal();
  };

  const openModal = () => {
    dispatch({ type: OPEN_MODAL_WINDOW });
  };

  const setMobileSize = () => {
    dispatch({ type: SET_TO_MOBILE });
  };

  const setWebSize = () => {
    dispatch({ type: SET_TO_WEB });
  };

  const sendTransactionSuccess = () => {
    dispatch({ type: CLOSE_MODAL_WINDOW });
    dispatch({ type: OPEN_INFO_MODAL });
    setTimeout(() => {
      closeInfoModal();
    }, 3000);
  };
  const sendTransactionError = message => {
    dispatch({ type: CLOSE_INCREASE_CREDIT_MODAL });
    dispatch({ type: CLOSE_DECREASE_CREDIT_MODAL });
    dispatch({ type: CLOSE_MODAL_WINDOW });
    dispatch({ type: TRANSACTION_ERROR, payload: message });
    dispatch({ type: OPEN_INFO_MODAL });
  };

  const setInfoMessage = message => {
    dispatch({ type: TRANSACTION_ERROR, payload: '' });
    dispatch({ type: SET_INFO_MESSAGE, payload: message });
  };

  const closeTransactionModal = () => {
    dispatch({ type: CLOSE_MODAL_WINDOW });
    dispatch({ type: CLOSE_INFO_MODAL });
  };

  const closeInfoModal = () => {
    dispatch({ type: CLOSE_INFO_MODAL });
  };

  const setOperationType = payload => {
    dispatch({ type: SET_OPERATION_TYPE, payload });
  };

  const setEntryParam = payload => {
    dispatch({ type: CHANGE_MODAL_PARAM, payload });
  };

  const setCashValue = payload => {
    dispatch({ type: CHANGE_MODAL_VALUE, payload });
  };

  const showIncreaseCreditModal = () => {
    dispatch({ type: SHOW_INCREASE_CREDIT_MODAL });
  };

  const closeIncreaseCreditModal = () => {
    dispatch({ type: CLOSE_INCREASE_CREDIT_MODAL });
  };

  const increaseCredit = () => {
    dispatch({ type: CLOSE_INCREASE_CREDIT_MODAL });
    dispatch({ type: OPEN_INFO_MODAL });
    setTimeout(() => {
      closeInfoModal();
    }, 3000);
  };

  const showConfirmModal = () => {
    dispatch({ type: SHOW_CONFIRM_MODAL });
  };

  const closeConfirmModal = () => {
    dispatch({ type: CLOSE_CONFIRM_MODAL });
  };

  const showDecreaseCreditModal = () => {
    dispatch({ type: SHOW_DECREASE_CREDIT_MODAL });
  };

  const closeDecreaseCreditModal = () => {
    dispatch({ type: CLOSE_DECREASE_CREDIT_MODAL });
  };

  const decreaseCredit = () => {
    dispatch({ type: CLOSE_DECREASE_CREDIT_MODAL });
    dispatch({ type: OPEN_INFO_MODAL });
    setTimeout(() => {
      closeInfoModal();
    }, 3000);
  };
  const setCashierCredit = (cashierCredit, has_credit) => {
    dispatch({ type: SET_CASHIER_CREDIT, cashierCredit, has_credit });
  };
  const setTransactions = payload => {
    let cashOutTransaction = [];
    let loadedFundsTransactions = [];
    payload.forEach(item => {
      if (item.type === TYPE_UNLOAD_FUNDS) {
        cashOutTransaction.push(item);
      } else {
        loadedFundsTransactions.push(item);
      }
    });
    dispatch({ type: SET_TRANSACTIONS, cashOutTransaction, loadedFundsTransactions });
  };
  const updateTransactions = payload => {
    let key;
    if (payload.type === TYPE_UNLOAD_FUNDS) {
      key = 'cashOutTransaction';
    } else {
      key = 'loadedFundsTransactions';
    }
    dispatch({ type: UPDATE_TRANSACTIONS, key, payload });
  };
  const setSiteInfo = payload => {
    dispatch({ type: SET_SITE_INFO, payload });
    localStorage.setItem('site_info', JSON.stringify(payload));
  };
  const setStatusChannel = payload => {
    dispatch({ type: SET_STATUS_CHANNEL, payload });
  };
  if (state.isFetching) {
    return <Preloader />;
  }

  return (
    <SocketContext value={{ socket: socket.current }}>
      <State value={state}>
        <Dispatch
          value={{
            login,
            logout,
            setError,
            setFilterValue,
            handleButtonCLick,
            setInfoMessage,
            sendTransactionSuccess,
            sendTransactionError,
            closeTransactionModal,
            closeInfoModal,
            setEntryParam,
            setOperationType,
            setCashValue,
            showIncreaseCreditModal,
            closeIncreaseCreditModal,
            increaseCredit,
            showDecreaseCreditModal,
            closeDecreaseCreditModal,
            decreaseCredit,
            setCashierCredit,
            setTransactions,
            updateTransactions,
            setSiteInfo,
            setStatusChannel,
            setFetchingStatus,
            showConfirmModal,
            closeConfirmModal
          }}
        >
          <ToastContainer />
          <div className='container'>{children}</div>
        </Dispatch>
      </State>
    </SocketContext>
  );
};

export default withRouter(Store);
