import { Socket } from 'phoenix';

export default class AppSocket {
  static async getInstance(url, params, isReconnect) {
    if (!AppSocket.instance || isReconnect) {
      AppSocket.instance = await new Socket(url, params);
      await AppSocket.instance.connect();
    }

    return AppSocket.instance;
  }
}
