import React, { memo } from 'react';

function Label({ type, value }) {
  const additionalClass = type.toLowerCase();
  return (
    <div className='label-container'>
      <div className={`label ${additionalClass}`}>
        <h4>{value}</h4>
      </div>
      <div className={`label-symbol ${additionalClass}`}>{value === 'In' ? '+' : '-'}</div>
    </div>
  );
}

export default memo(Label);
