import React, { useContext, memo } from 'react';
import imageManager from '../../media/imageManager';
import { stateContext } from '../../context/appContext';
import UserTopRightMenu from './UserTopRightMenu';
import getUserName from '../../helpers/getUserName';
import { CSSTransition } from 'react-transition-group';

function HeaderMenu({ isOpen, handleMenuClick }) {
  const { user } = useContext(stateContext);
  return (
    <div className='header-content-information'>
      <div className='information-user'>
        <div onClick={handleMenuClick} className='information-user-account'>
          <img src={imageManager.account} alt='logo' />
          <span className='user-name'>{getUserName(user)}</span>
          <i className={`icon-arrow down`} />
        </div>
        <CSSTransition in={isOpen} classNames='user-top-menu' timeout={1000} appear unmountOnExit>
          <UserTopRightMenu handleClick={handleMenuClick} />
        </CSSTransition>
      </div>
    </div>
  );
}

export default memo(HeaderMenu);
