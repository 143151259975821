import React, { useContext, useState } from 'react';
import Header from './Header';
import { stateContext } from './../../context/appContext';
import Menu from '../Menu';
import { CSSTransition } from 'react-transition-group';
import UserMenu from './UserMenu';
import { TYPE_UNLOAD_FUNDS, TYPE_LOAD_FUNDS } from '../../consts';

function HomeForMobile({ handleChangeSite }) {
  const { operationType, loadedFundsTransactions, cashOutTransaction } = useContext(stateContext);
  const [userMenu, setUserMenu] = useState(false);

  const openUserMenu = () => setUserMenu(true);
  const closeUserMenu = () => setUserMenu(false);

  return (
    <>
      <CSSTransition in={userMenu} classNames='user-menu' timeout={500} appear unmountOnExit>
        <UserMenu closeUserMenu={closeUserMenu} handleChangeSite={handleChangeSite} />
      </CSSTransition>
      <Header openUserMenu={openUserMenu} menuIsOpen={userMenu} />
      <div onClick={closeUserMenu} className='content-container'>
        <div className='content'>
          {operationType === TYPE_LOAD_FUNDS ? (
            <Menu
              transactions={loadedFundsTransactions}
              keyBoard={['F1', 'F2', 'F3']}
              type={TYPE_LOAD_FUNDS}
            />
          ) : (
            <Menu
              transactions={cashOutTransaction}
              keyBoard={['F4', 'F5', 'F6']}
              type={TYPE_UNLOAD_FUNDS}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default HomeForMobile;
