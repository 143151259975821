import React, { useContext, memo } from 'react';
import Search from '../../media/icons/search.svg';
import { dispatchContext } from '../../context/appContext';

function FilterInput({ placeholder, type, value, keyButton }) {
  const { setFilterValue } = useContext(dispatchContext);

  const handleChange = event => {
    setFilterValue(event.target.value, type);
  };

  return (
    <div className='filter-input-container'>
      <span className={`input-key ${type}`}>{keyButton}</span>
      <input
        placeholder={placeholder}
        onChange={handleChange}
        id={`filter-input-${type}`}
        className='filter-input'
        type='number'
        value={value}
        pattern='[0-9]*'
      />
      <img className='filter-input-icon' src={Search} alt='search' />
    </div>
  );
}

export default memo(FilterInput);
