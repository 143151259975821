export const reactSelectCustomStyles = (beforeProp = false) => ({
  menuList: props => ({
    ...props,
    '&::-webkit-scrollbar': {
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
      width: 6,
      borderRadius: 6,
      outline: 'none',
      border: 'none',
      backgroundColor: '#494d71'
    },
    '&::-webkit-scrollbar-thumb': {
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
      borderRadius: 4,
      width: 4,
      backgroundColor: '#323558'
    }
  }),
  control: (props, state) => {
    const { menuIsOpen } = state;
    const commonStyles = {
      ...props,
      backgroundColor: '#323558',
      borderRadius: '90px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 10,
      borderStyle: 'none',
      boxShadow: 'none',
      ...(beforeProp && {
        ':before': {
          color: '#57c7a1',
          content: `'${beforeProp}'`
        }
      }),
      '&:hover': {
        borderStyle: 'none'
      }
    };
    const openStyles = {
      borderRadius: '25px 25px 0 0',
      borderColor: 'none'
    };

    return menuIsOpen
      ? {
          ...commonStyles,
          ...openStyles
        }
      : commonStyles;
  },
  placeholder: () => ({
    color: '#fff'
    // padding: '0.57vw 1.2vw' /* 11px 23px */,
    // fontSize: '1.04vw' /* 20px */,
    // lineHeight: '1.354vw' /* 26px */,
    // position: 'absolute',
    // top: '50%',
    // transform: 'translateY(-50%)'
  }),
  input: () => ({
    color: '#fff',
    fontSize: 16 /* 20px */,
    lineHeight: '1.354vw' /* 26px */,
    boxSizing: 'border-box',
    maxWidth: 'calc(100% - 8px)'
  }),
  option: (props, state) => ({
    ...props,
    padding: '5px 5px 5px 20px',
    fontSize: 16,
    textAlign: 'left',
    transition: 'all 0.3s ease',
    color: state.isSelected ? '#fff' : '#9da0c7',
    //backgroundColor: state.isSelected ? 'red' : 'rgba(50,53,88,0.97)',
    '&:hover': {
      cursor: 'pointer',
      color: '#fff',
      backgroundColor: 'transparent'
    }
  }),
  singleValue: param => ({
    ...param,
    color: '#9da0c7',
    //fontSize: 16,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    whiteSpace: 'nowrap'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  menu: () => ({
    backgroundColor: 'rgba(50, 53, 88, 0.97)',
    position: 'absolute',
    width: '100%',
    borderRadius: '0 0 20px 20px',
    color: '#fff',
    height: 315,
    zIndex: 1
  }),
  noOptionsMessage: () => ({
    color: '#fff',
    textAlign: 'left',
    padding: '0.57vw 1.2vw' /* 11px 23px */,
    fontSize: 16 /* 20px */,
    lineHeight: '1.875vw' /* 36px */
  })
});

export const CASHIER_ROLE = 'cashier';
export const OPERATOR_ROLE = 'operator';

export const SOCKET_GET_TRANSACTIONS = 'get_transactions';
export const SOCKET_CREDIT_UNIT = 'credit_unit';
export const SOCKET_DEBIT_UNIT = 'debit_unit';
export const SOCKET_GET_UNIT = 'get_unit';
export const SOCKET_EVENT_POS_STATE_UPDATE = 'pos_state_updated';
export const SOCKET_EVENT_TRANSACTIONS = 'transaction';

export const TYPE_UNLOAD_FUNDS = 'unload_funds';
export const TYPE_LOAD_FUNDS = 'load_funds';

export const UNIT_STATUS_CASH_OUT = 'cash_out';
export const UNIT_STATUS_HOLD = 'hold';
export const MAX_COUNT_RECCONECT = 5;
export const MAX_AMOUNT = 5000;
