import React, { useContext } from 'react';
import imageManager from '../../media/imageManager';
import { stateContext } from '../../context/appContext';
import ListMenuItem from '../ListMenuItem';
import getUserName from '../../helpers/getUserName';

const UserMenu = ({ closeUserMenu }) => {
  const { user } = useContext(stateContext);

  return (
    <div className='user-menu-container' style={{ height: 'auto', padding: '6px 0' }}>
      <div className='user-menu-body'>
        <div className='user-menu-header'>
          <div className='user-menu-name'>
            <img className='user-menu-icon' src={imageManager.userMenuWhite} alt='user' />
            <span className='name'>{getUserName(user)}</span>
          </div>
          <img
            onClick={closeUserMenu}
            className='user-menu-close'
            src={imageManager.close}
            alt='close'
          />
        </div>
        <div className='user-menu-items'>
          <ListMenuItem />
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
