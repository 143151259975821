import React from 'react';
import HistoryItem from './HistoryItem';

const HistoryList = ({ transactions, hasCredit }) => {
  const renderHistory = data => {
    return data.map((item, index) => (
      <HistoryItem
        index={index}
        key={item.timestamp}
        type={item.account.type}
        theme={item.type}
        date={item.timestamp}
        amount={item.amount}
        cashier_credit={item.cashier_credit}
        label={item.account.entity.label}
        hasCredit={hasCredit}
      />
    ));
  };

  return (
    <>
      <table className='table-history-sections'>
        <tbody className='history-container'>
          <tr className='history-item history-title'>
            <td className='history-date'> </td>
            <td className='history-time'></td>
            <td className='history-type'></td>
            <td className='history-type'></td>
            {transactions.length > 0 && <td className='history-value'>Amount</td>}
            {hasCredit && <td className='history-value'>Credit</td>}
          </tr>
        </tbody>
      </table>
      <table className='history-sections table-history-sections'>
        <tbody className='history-container'>{renderHistory(transactions)}</tbody>
      </table>
    </>
  );
};

export default HistoryList;
