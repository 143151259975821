import React, { useContext } from 'react';
import imageManager from '../../media/imageManager';
import { dispatchContext } from '../../context/appContext';
import UserLabel from './UserLabel';
import Label from './Label';
import BalanceLabel from '../BalanceLabel';
import { TYPE_UNLOAD_FUNDS, TYPE_LOAD_FUNDS } from '../../consts';
import screenfull from 'screenfull';

function Header({ openUserMenu, menuIsOpen }) {
  const { setOperationType } = useContext(dispatchContext);

  const setActiveSection = section => {
    setOperationType(section);
  };
  function launchFullScreen() {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  }
  return (
    <div className='header mobile'>
      <div className='header-container'>
        <div className='header-content-top'>
          <div className='header-content-logo-game'>
            <div className='logo-container'>
              <div className='content-logo' onClick={launchFullScreen}>
                <img src={imageManager.logo} alt='logo' />
              </div>
            </div>
          </div>
          <BalanceLabel />
          <UserLabel openUserMenu={openUserMenu} menuIsOpen={menuIsOpen} />
        </div>
        <div className='labels'>
          <Label pickSection={setActiveSection} label={TYPE_LOAD_FUNDS} value='In' />
          <Label pickSection={setActiveSection} label={TYPE_UNLOAD_FUNDS} value='Out' />
        </div>
      </div>
    </div>
  );
}

export default Header;
