import React, { useContext, useEffect, useState } from 'react';
import MenuButton from '../MenuButton';
import FilterInput from '../FilterInput';
import History from '../History';
import { dispatchContext, stateContext } from './../../context/appContext';
import { TYPE_LOAD_FUNDS } from '../../consts';

function Menu({ type, keyBoard, transactions }) {
  const { handleButtonCLick } = useContext(dispatchContext);
  const { inSearch, outSearch } = useContext(stateContext);
  const [searchResults, setSearchResults] = useState([]);
  const handleClick = name => {
    handleButtonCLick(name.toLowerCase(), type);
  };

  useEffect(() => {
    try {
      const filterTransactions = params => {
        if (params) {
          let filteredTransactions = transactions.filter(
            item => item.account.entity.label === params
          );
          if (!filteredTransactions.length) {
            filteredTransactions = transactions.filter(item =>
              item.account.entity.label.includes(params)
            );
          }
          return filteredTransactions;
        }
        return transactions;
      };
      let searchParams = type === TYPE_LOAD_FUNDS ? inSearch : outSearch;
      let filteredValue = filterTransactions(searchParams);
      setSearchResults(filteredValue);
    } catch (e) {
      console.log(e);
    }
  }, [inSearch, outSearch, type, transactions]);
  return (
    <>
      <div className='button-group'>
        <MenuButton
          disabled={true}
          action={handleClick}
          theme={type}
          name='Phone'
          keyButton={keyBoard[0]}
        />
        <MenuButton action={handleClick} theme={type} name='Unit' keyButton={keyBoard[1]} />
        <MenuButton
          disabled={true}
          action={handleClick}
          theme={type}
          name='Token'
          keyButton={keyBoard[2]}
        />
      </div>
      <FilterInput
        type={type}
        keyButton={keyBoard[3]}
        value={type === TYPE_LOAD_FUNDS ? inSearch : outSearch}
        placeholder={`Type «Unit/Phone» to search «${
          type === TYPE_LOAD_FUNDS ? 'IN' : 'OUT'
        }» history…`}
      />
      <div className='history-sections'>
        <History theme={type} transactions={searchResults} />
      </div>
    </>
  );
}

export default Menu;
