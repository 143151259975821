import React from 'react';
import displayIcon from '../../helpers/displayIcon';
import { getDate, getTime } from '../../helpers/date';
import currencyFormatter from '../../helpers/currencyFormatter';

function HistoryItem({ date, type, amount, theme, label }) {
  return (
    <div className='history-item'>
      <div className='history-date'>{getDate(date)}</div>
      <div className='history-time'>{getTime(date)}</div>
      <div className='history-type'>{displayIcon(type, theme)}</div>
      <div className='history-desc'>{label}</div>
      <div className={`history-value ${theme}`}>{currencyFormatter(amount)}</div>
    </div>
  );
}

export default HistoryItem;
