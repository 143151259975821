import React, { useState, useContext, useCallback, useRef, useEffect } from 'react';
import Select, { components } from 'react-select';
import { reactSelectCustomStyles } from '../../consts';
import { stateContext } from '../../context/appContext';
import getUserName from '../../helpers/getUserName';

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <i className='icon-arrow down' />
    </components.DropdownIndicator>
  );
};

function CustomSelect({
  handleChange,
  menuIsOpen,
  handleMenuClose,
  value,
  options,
  keyButton,
  beforeProp,
  isVisibleOptionId = true
}) {
  const { isMobile } = useContext(stateContext);
  const selectRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(value);

  const handleOnChange = selectedOption => {
    setSelectedOption(selectedOption);
    handleChange(selectedOption);
  };

  const setKeyDown = useCallback(
    event => {
      if (keyButton && event.code === keyButton) {
        event.preventDefault();
        selectRef.current.focus();
      }
    },
    [keyButton]
  );

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener('keydown', setKeyDown, false);
    }
    return () => window.removeEventListener('keydown', setKeyDown, false);
  }, [isMobile, setKeyDown]);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  function customOptionLabel(option) {
    if (isVisibleOptionId && option['id']) {
      return `${option['id']} - ${option['name']}`;
    }
    return getUserName(option);
  }

  return (
    <div title={selectedOption.name}>
      <Select
        styles={reactSelectCustomStyles(!isMobile ? beforeProp : null)}
        options={options}
        value={selectedOption}
        onChange={handleOnChange}
        getOptionValue={option => option['id']}
        getOptionLabel={option => customOptionLabel(option)}
        components={{ DropdownIndicator }}
        menuIsOpen={menuIsOpen}
        onMenuClose={handleMenuClose}
        keyButton={keyButton}
        ref={selectRef}
      />
    </div>
  );
}

export default CustomSelect;
