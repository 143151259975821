import getUserName from './getUserName';

export const sortById = sitesList => {
  let sortedList = [...sitesList];
  sortedList.sort((a, b) => {
    if (a.id > b.id) return 1;
    if (a.id === b.id) return 0;
    if (a.id < b.id) return -1;
  });
  return sortedList;
};

export const sortByName = sitesList => {
  let sortedList = [...sitesList];
  sortedList.sort((a, b) => {
    return getUserName(a).localeCompare(getUserName(b));
  });
  return sortedList;
};
