import React, { useContext, useState } from 'react';
import Datetime from 'react-datetime';
import { CSSTransition } from 'react-transition-group';
import Select from '../Select';
import CreditSettings from '../CreditSettings';
import { stateContext } from '../../context/appContext';
import imageManager from '../../media/imageManager';
import getUserName from '../../helpers/getUserName';
import FilterCurrent from '../FilterCurrent';
import { getDate, getTime } from '../../helpers/date';
import currencyFormatter from '../../helpers/currencyFormatter';

function FilterControl({
  cashiers,
  isOperator,
  handleChangeTimeFrom,
  handleChangeTimeTo,
  onChangeCashier,
  handleChangeSiteId,
  currentCashier,
  isVisibleCreditSettings = false
}) {
  const { user, isMobile } = useContext(stateContext);
  const [timeFrom, setTimeFrom] = useState(new Date().setHours(0, 0, 0, 0));
  const [timeTo, setTimeTo] = useState(new Date().setHours(23, 59, 59, 0));
  const [site, setSite] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleFilterClick = () => {
    setIsOpen(isOpen => !isOpen);
  };

  let selectedSite;
  if (site) {
    selectedSite = {
      id: site.id,
      name: site.name
    };
  } else if (user.sites.length === 1) {
    selectedSite = {
      id: user.sites[0].id,
      name: user.sites[0].name
    };
  } else {
    selectedSite = {
      id: 0,
      name: 'All Sites'
    };
  }

  let selectedCashier;
  if (currentCashier) {
    selectedCashier = {
      name: getUserName(currentCashier)
    };
  } else if (cashiers.length === 1) {
    selectedCashier = {
      name: cashiers[0].name
    };
  } else {
    selectedCashier = {
      name: 'All Cashiers'
    };
  }

  let currentFilter;
  if (isMobile) {
    currentFilter = [
      `From ${getDate(timeFrom)} ${getTime(timeFrom)} to ${getDate(timeTo)} ${getTime(timeTo)}`,
      (selectedSite.id ? `${selectedSite.id} - ` : '') + selectedSite.name,
      selectedCashier.name
    ];

    if (isVisibleCreditSettings) {
      currentFilter.push(
        `Credit Remaining ${currentCashier.name} ${
          currentCashier && currentCashier.cashier_credit
            ? currencyFormatter(currentCashier.cashier_credit)
            : 'R0.00'
        }`
      );
    }
  }

  const dateTimeInputProps = isMobile ? { readOnly: isMobile } : null;

  return (
    <div
      className={`settings-panel${isMobile ? ' mobile' : ''}`}
      onClick={isMobile && !isOpen ? handleFilterClick : null}
    >
      {isMobile && (
        <div
          className={`settings-panel-title${isOpen ? ' active' : ''}`}
          onClick={isOpen ? handleFilterClick : null}
        >
          Filters
        </div>
      )}

      {isMobile && !isOpen && <FilterCurrent currentValues={currentFilter} />}

      {(!isMobile || (isMobile && isOpen)) && (
        <div className='settings-panel-fields'>
          <div className='settings-panel-field date-time'>
            <div className='settings-panel-content'>
              <div className='settings-panel-label settings-panel-label--iconShift'>
                <img src={imageManager.calendar} alt='icon' />
                <span>Select Date/Time From</span>
              </div>

              <Datetime
                dateFormat='YYYY-MM-DD'
                timeFormat='HH:mm'
                value={timeFrom}
                onBlur={() => handleChangeTimeFrom(timeFrom)}
                closeOnSelect={true}
                onChange={val => setTimeFrom(val)}
                inputProps={dateTimeInputProps}
              />
            </div>
          </div>
          <div className='settings-panel-field date-time'>
            <div className='settings-panel-content'>
              <div className='settings-panel-label settings-panel-label--iconShift'>
                <img src={imageManager.calendar} alt='icon' />
                <span>Select Date/Time To</span>
              </div>
              <Datetime
                dateFormat='YYYY-MM-DD'
                timeFormat='HH:mm'
                value={timeTo}
                onBlur={val => handleChangeTimeTo(val)}
                closeOnSelect={true}
                onChange={val => setTimeTo(val)}
                inputProps={dateTimeInputProps}
              />
            </div>
          </div>
          {isOperator && (
            <>
              <div className={`settings-panel-field${!isMobile ? ' border-left' : ''}`}>
                <div className='settings-panel-content'>
                  <div className='settings-panel-label'>
                    <img src={imageManager.chain} alt='icon' />
                    <span>Select a Site</span>
                  </div>
                  <Select
                    value={selectedSite}
                    options={[{ id: 0, name: 'All Sites' }, ...user.sites]}
                    handleChange={val => {
                      handleChangeSiteId(val.id);
                      setSite(val);
                    }}
                    keyButton='F1'
                    beforeProp='F1'
                  />
                </div>
              </div>
              <div className={`settings-panel-field${!isMobile ? ' border-left' : ''}`}>
                <div className='settings-panel-content'>
                  <div className='settings-panel-label'>
                    <img src={imageManager.accountLight} alt='icon' />
                    <span>Select a Cashier</span>
                  </div>
                  <Select
                    value={selectedCashier}
                    options={[{ name: 'All Cashiers' }, ...cashiers]}
                    handleChange={val => {
                      onChangeCashier(val);
                    }}
                    isVisibleOptionId={false}
                    keyButton='F2'
                    beforeProp='F2'
                  />
                </div>
              </div>
            </>
          )}
          <CSSTransition
            in={isVisibleCreditSettings}
            classNames='field'
            timeout={500}
            appear
            unmountOnExit
          >
            <div className={`settings-panel-credit${!isMobile ? ' border-left' : ''}`}>
              <div className='settings-panel-content'>
                <CreditSettings currentCashier={currentCashier} />
              </div>
            </div>
          </CSSTransition>
        </div>
      )}
    </div>
  );
}

export default FilterControl;
