import React from 'react';
import CreditItem from './CreditItem';

const CreditList = ({ transactions }) => {
  const renderHistory = data => {
    return data.map((item, index) => (
      <CreditItem
        index={index}
        key={item.timestamp}
        date={item.timestamp}
        amount={item.amount}
        theme={item.type}
        cashier_credit={item.cl_balance}
      />
    ));
  };

  return (
    <>
      <table className='table-history-sections'>
        <tbody className='history-container'>
          <tr className='history-item history-title'>
            <td className='history-date'> </td>
            <td className='history-time'></td>
            <td className='history-type'></td>
            <td className='history-type'></td>
            {transactions.length > 0 && (
              <>
                <td className='history-value'>Amount</td>
                <td className='history-value'>Credit</td>
              </>
            )}
          </tr>
        </tbody>
      </table>
      <table className='history-sections table-history-sections'>
        <tbody className='history-container'>{renderHistory(transactions)}</tbody>
      </table>
    </>
  );
};

export default CreditList;
