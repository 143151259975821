import React, { useContext, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { stateContext, dispatchContext } from './../../context/appContext';
import Tabs from './Tabs';
import IncreaseCreditModal from '../../components/Modals/IncreaseCredit';
import DecreaseCreditModal from '../../components/Modals/DecreaseCredit';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import HeaderContent from '../../components/Header';
import HistoryTab from '../../components/HistoryTab';
import CreditTab from '../../components/CreditTab';
import InfoModal from '../../components/Modals/InfoModal';
import AdminForMobile from '../../components/AdminForMobile';
import { getAllCashiers, enableCredit, disableCredit } from '../../providers';
import { OPERATOR_ROLE, CASHIER_ROLE } from '../../consts';
import { toast } from 'react-toastify';
import { sortByName } from '../../helpers/sites';

function Home() {
  const {
    increaseCreditModalIsOpen,
    decreaseCreditModalIsOpen,
    confirmModalIsOpen,
    user,
    infoModal,
    isMobile
  } = useContext(stateContext);
  const {
    showConfirmModal,
    closeConfirmModal,
    closeIncreaseCreditModal,
    closeDecreaseCreditModal,
    closeInfoModal
  } = useContext(dispatchContext);
  let currentUser = user.role === CASHIER_ROLE ? user : null;
  const [currentCashier, setCurrentCashier] = useState(currentUser);
  const [activeTab, setActiveTab] = useState('history');
  const [cashiers, setCashiers] = useState([]);
  const [isEnabledAdminCredit, toggleAdminEnableCredit] = useState(false);

  useEffect(() => {
    toggleAdminEnableCredit(!!currentCashier && !!currentCashier.has_credit);
  }, [currentCashier]);

  useEffect(() => {
    if (user.role === OPERATOR_ROLE) {
      fetchAllCashiers();
    }
  }, [user.role]);

  useEffect(() => {
    const setKeyDown = event => {
      if (event.key === 'Escape') {
        event.preventDefault();
        closeConfirmModal();
        closeIncreaseCreditModal();
        closeDecreaseCreditModal();
      }
      if ((infoModal && !increaseCreditModalIsOpen) || (infoModal && !decreaseCreditModalIsOpen)) {
        return closeInfoModal();
      }
    };
    if (!isMobile) {
      window.addEventListener('keydown', setKeyDown, false);
    }
    return () => {
      window.removeEventListener('keydown', setKeyDown, false);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoModal, increaseCreditModalIsOpen, decreaseCreditModalIsOpen]);

  async function fetchAllCashiers() {
    try {
      const res = await getAllCashiers();
      setCashiers(sortByName(res.cashiers));
      if (res.cashiers.length === 1) {
        setCurrentCashier(res.cashiers[0]);
      }
    } catch (e) {
      toast.error(e.msg, {
        autoClose: false
      });
    }
  }

  async function changeCashierCredit(isEnable) {
    if (currentCashier && currentCashier.id) {
      try {
        let res;
        if (isEnable) {
          res = await enableCredit(currentCashier.id);
        } else {
          res = await disableCredit(currentCashier.id);
        }
        updateCashiers(res);
      } catch (e) {
        toast.error(e.msg, {
          autoClose: false
        });
      }
    }
  }

  function handleChangeCashierCredit(isEnable) {
    if (isEnable) {
      changeCashierCredit(isEnable);
    } else {
      showConfirmModal();
    }
  }

  function updateCashiers(data) {
    const index = cashiers.findIndex(obj => obj.id === data.cashier.id);
    cashiers[index] = data.cashier;
    setCashiers(sortByName(cashiers));
    setCurrentCashier(data.cashier);
  }

  return (
    <div className={`app-container admin ${isMobile ? 'mobile' : ''}`}>
      <CSSTransition
        in={increaseCreditModalIsOpen}
        classNames='modal'
        timeout={500}
        appear
        unmountOnExit
      >
        <IncreaseCreditModal cashier={currentCashier} onClickIncreaseCredit={updateCashiers} />
      </CSSTransition>
      <CSSTransition
        in={decreaseCreditModalIsOpen}
        classNames='modal'
        timeout={500}
        appear
        unmountOnExit
      >
        <DecreaseCreditModal cashier={currentCashier} onClickIncreaseCredit={updateCashiers} />
      </CSSTransition>
      <CSSTransition in={confirmModalIsOpen} classNames='modal' timeout={500} appear unmountOnExit>
        <ConfirmModal cashier={currentCashier} onChangeAdminCredit={changeCashierCredit} />
      </CSSTransition>
      <CSSTransition in={infoModal} classNames='modal' timeout={500} appear unmountOnExit>
        <InfoModal />
      </CSSTransition>
      {isMobile ? (
        <AdminForMobile
          cashiers={cashiers}
          currentCashier={currentCashier}
          setCurrentCashier={setCurrentCashier}
          onChangeAdminCredit={handleChangeCashierCredit}
          isEnabledAdminCredit={isEnabledAdminCredit}
        />
      ) : (
        <>
          <div className='header'>
            <div className='header-container'>
              <HeaderContent />
              <Tabs
                currentCashier={currentCashier}
                onChangeActiveTab={setActiveTab}
                onChangeAdminCredit={handleChangeCashierCredit}
                isEnabledAdminCredit={
                  activeTab === 'credit' && !!currentCashier && !!currentCashier.has_credit
                }
                activeTab={activeTab}
                isVisibleSwitch={activeTab === 'credit' && !!currentCashier && !!currentCashier.id}
              />
            </div>
          </div>
          {activeTab === 'history' ? (
            <HistoryTab
              cashiers={cashiers}
              currentCashier={currentCashier}
              onChangeCashier={setCurrentCashier}
              cashierId={currentCashier ? currentCashier.id : 0}
            />
          ) : (
            <CreditTab
              cashiers={cashiers}
              currentCashier={currentCashier}
              onChangeCashier={setCurrentCashier}
              isVisibleCreditSettings={isEnabledAdminCredit}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Home;
