import React, { useContext } from 'react';
import { stateContext } from '../../context/appContext';
import { Route, Redirect } from 'react-router-dom';

function ProtectedRouter({ component, ...props }) {
  const { isAuth, isFetching } = useContext(stateContext);
  return isAuth && !isFetching ? (
    <Route {...props} component={component} />
  ) : (
    <Redirect to='/login' />
  );
}

export default ProtectedRouter;
