import React, { useContext, useState, useEffect } from 'react';
import imageManager from '../../media/imageManager';
import { dispatchContext, stateContext } from '../../context/appContext';
import Input from '../../components/utils/Input';
import Button from '../../components/utils/Button';

function Login(props) {
  const { login, setError } = useContext(dispatchContext);
  const { error, isAuth } = useContext(stateContext);
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const [autofill, setAutofill] = useState(false);

  useEffect(() => {
    if (isAuth) {
      props.history.push('/');
    }
  });

  const handleChange = event => {
    if (event.target.name === 'loginId') {
      setLoginId(event.target.value);
    } else {
      setPassword(event.target.value);
    }
  };

  const checkParams = () => {
    return !(loginId.length >= 3 && password.length >= 3 && !error);
  };

  const handleClick = event => {
    event.preventDefault();
    if (loginId && password) login(loginId, password);
  };

  const handleFocus = () => {
    setError();
    if (autofill) {
      setAutofill(false);
    }
  };

  const handleAutoFill = event => {
    if (event.animationName === 'onAutoFillStart') {
      setAutofill(true);
    }
  };

  return (
    <div className='content login'>
      <form className='content-login'>
        <div className='content-logo'>
          <img src={imageManager.logo} alt='logo' />
        </div>
        <h3 className={`content-header ${error ? 'error' : ''}`}>{error ? error : 'POS Login'}</h3>
        <Input
          type='text'
          name='loginId'
          placeholder='Username'
          error={false}
          id={'login_id'}
          onChange={handleChange}
          value={loginId}
          onFocus={handleFocus}
          onAnimationStart={handleAutoFill}
        />
        <Input
          placeholder='Password'
          type='password'
          name='password'
          error={false}
          id={'password_id'}
          onChange={handleChange}
          value={password}
          onFocus={handleFocus}
        />
        <Button error={checkParams() && !autofill} value='Login' onClick={handleClick} />
      </form>
    </div>
  );
}

export default Login;
