import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  PRESS_BUTTON,
  SET_INFO_MESSAGE,
  ERROR_NULL,
  IN_FILTERED_VALUE,
  OUT_FILTERED_VALUE,
  SET_OPERATION_TYPE,
  OPEN_MODAL_WINDOW,
  CLOSE_MODAL_WINDOW,
  GET_HISTORY,
  CHANGE_MODAL_PARAM,
  CHANGE_MODAL_VALUE,
  SET_TO_MOBILE,
  SET_TO_WEB,
  LOGOUT,
  IS_AUNTIFICATED,
  TRANSACTION_ERROR,
  OPEN_INFO_MODAL,
  CLOSE_INFO_MODAL,
  SHOW_INCREASE_CREDIT_MODAL,
  CLOSE_INCREASE_CREDIT_MODAL,
  SHOW_DECREASE_CREDIT_MODAL,
  CLOSE_DECREASE_CREDIT_MODAL,
  SET_CASHIER_CREDIT,
  SET_TRANSACTIONS,
  UPDATE_TRANSACTIONS,
  SET_SITE_INFO,
  SET_STATUS_CHANNEL,
  SET_FETCHING_STATUS,
  SHOW_CONFIRM_MODAL,
  CLOSE_CONFIRM_MODAL
} from './actionTypes';
import { initialState } from './appContext';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_FETCHING_STATUS:
      return { ...state, isFetching: action.payload };
    case LOGOUT:
      return { ...initialState, isFetching: false };

    case LOGIN_SUCCESS:
      return { ...state, isAuth: true, user: action.payload };

    case SET_SITE_INFO:
      return { ...state, siteInfo: action.payload };

    case SET_STATUS_CHANNEL:
      return { ...state, isOnline: action.payload };

    case GET_HISTORY:
      return { ...state, isAuth: true, history: action.payload };

    case IS_AUNTIFICATED:
      return { ...state, isAuth: true };

    case LOGIN_ERROR:
      return { ...state, isAuth: false, error: action.payload };

    case TRANSACTION_ERROR:
      return { ...state, transactionError: action.payload };

    case SET_INFO_MESSAGE:
      return { ...state, infoMessage: action.payload };

    case ERROR_NULL:
      return { ...state, error: '' };

    case IN_FILTERED_VALUE:
      return { ...state, inSearch: action.payload };

    case SET_TO_MOBILE:
      return { ...state, isMobile: true };

    case SET_TO_WEB:
      return { ...state, isMobile: false };

    case OUT_FILTERED_VALUE:
      return { ...state, outSearch: action.payload };

    case PRESS_BUTTON: {
      const loadParams = { ...state.loadParams, type: action.payload };
      return { ...state, loadParams };
    }
    case CHANGE_MODAL_VALUE: {
      const loadParams = { ...state.loadParams, value: action.payload };
      return { ...state, loadParams };
    }
    case CHANGE_MODAL_PARAM: {
      const loadParams = { ...state.loadParams, param: action.payload };
      return { ...state, loadParams };
    }

    case SET_OPERATION_TYPE:
      return { ...state, operationType: action.payload };

    case OPEN_MODAL_WINDOW:
      const loadParams = { ...state.loadParams, param: '', value: '' };
      return { ...state, modalIsOpen: true, loadParams };

    case OPEN_INFO_MODAL:
      return { ...state, infoModal: true };

    case CLOSE_MODAL_WINDOW:
      return { ...state, modalIsOpen: false };

    case CLOSE_INFO_MODAL:
      return { ...state, infoModal: false };

    case SHOW_INCREASE_CREDIT_MODAL:
      return { ...state, increaseCreditModalIsOpen: true };

    case CLOSE_INCREASE_CREDIT_MODAL:
      return { ...state, increaseCreditModalIsOpen: false };

    case SHOW_DECREASE_CREDIT_MODAL:
      return { ...state, decreaseCreditModalIsOpen: true };

    case CLOSE_DECREASE_CREDIT_MODAL:
      return { ...state, decreaseCreditModalIsOpen: false };

    case SET_CASHIER_CREDIT:
      return { ...state, cashierCredit: action.cashierCredit, has_credit: action.has_credit };
    case SET_TRANSACTIONS:
      return {
        ...state,
        cashOutTransaction: action.cashOutTransaction,
        loadedFundsTransactions: action.loadedFundsTransactions
      };
    case UPDATE_TRANSACTIONS:
      return { ...state, [action.key]: [action.payload, ...state[action.key]] };
    case SHOW_CONFIRM_MODAL:
      return { ...state, confirmModalIsOpen: true };
    case CLOSE_CONFIRM_MODAL:
      return { ...state, confirmModalIsOpen: false };
    default:
      return state;
  }
};

export default reducer;
