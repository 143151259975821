import React, { useContext, useState, useEffect } from 'react';
import imageManager from '../../media/imageManager';
import { dispatchContext } from '../../context/appContext';
import Button from '../utils/Button';
import Input from '../utils/Input';
import deleteGaps from '../../helpers/deleteGapsFromString';
import currencyFormatter from '../../helpers/currencyFormatter';
import getUserName from '../../helpers/getUserName';
import { increaseCashierCredit } from '../../providers';

function RenderModals({ cashier, onClickIncreaseCredit }) {
  const [amount, setAmount] = useState('');
  const [isDisabledButton, setStatusButton] = useState(false);
  const {
    closeIncreaseCreditModal,
    setCashValue,
    setInfoMessage,
    increaseCredit,
    sendTransactionError
  } = useContext(dispatchContext);

  useEffect(() => {
    const inputAmount = document.getElementById('input_amount');
    inputAmount.focus();
  }, []);

  function handleChange(event) {
    if (event.target.validity.valid) {
      setAmount(event.target.value);
    }
  }

  function setMaskForValue(value) {
    const num = deleteGaps(value);
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  async function fetchIncreaseCredit() {
    try {
      setStatusButton(true);
      const preparedAmount = deleteGaps(amount);
      const res = await increaseCashierCredit(cashier.id, preparedAmount * 100);
      onClickIncreaseCredit(res);
      setInfoMessage('Credit Was Successfully Increased');
      increaseCredit();
      setCashValue(preparedAmount);
    } catch (error) {
      sendTransactionError(error.msg);
    }
  }

  return (
    <div className='modal-container'>
      <div className='overlay' />
      <div className='modal-body'>
        <div className='modal-header'>
          <div className='logo-container'>
            <div className='content-logo'>
              <img src={imageManager.logo} alt='logo' />
            </div>
            <span>Increase Credit</span>
          </div>
          <img
            className='close-model'
            onClick={closeIncreaseCreditModal}
            src={imageManager.close}
            alt='close'
          />
        </div>
        <div className='modal-info'>
          <div className='information-credit'>
            <div className='credit-label'>
              <img src={imageManager.warning} alt='logo' />
              <div>
                <p>Credit Remaining</p>
                <p className='highlighted'>{getUserName(cashier)}</p>
              </div>
            </div>
            {
              <span className='credit-value credit-value--alt'>
                {currencyFormatter(cashier.cashier_credit)}
              </span>
            }
          </div>
        </div>

        <div className={`modal-field`}>
          <div className={`value-fild`}>
            <label htmlFor='#check_amount'>Enter Amount</label>
            <Input
              type='text'
              name='check'
              id='input_amount'
              onChange={handleChange}
              value={setMaskForValue(amount)}
              pattern='[ 0-9]*'
              inputMode='numeric'
            />
          </div>
        </div>
        <div className={`modal-button`}>
          {
            <Button
              value='Increase'
              error={!amount || parseInt(amount) === 0}
              onClick={fetchIncreaseCredit}
              id='action_button'
              disabled={isDisabledButton || (!amount || parseInt(amount) === 0)}
            />
          }
        </div>
      </div>
    </div>
  );
}

export default RenderModals;
