import React, { useContext } from 'react';
import imageManager from '../../media/imageManager';
import { stateContext } from '../../context/appContext';
import ListMenuItem from '../ListMenuItem';
import getUserName from '../../helpers/getUserName';

const UserTopRightMenu = ({ handleClick }) => {
  const { user } = useContext(stateContext);
  return (
    <div className={`user-menu-top-right`}>
      <div onClick={handleClick} className='menu-item'>
        <img src={imageManager.accountWhite} alt='logo' />
        <div className='name'>{getUserName(user)}</div>
        <i className='icon-arrow' />
      </div>
      <ListMenuItem />
    </div>
  );
};

export default UserTopRightMenu;
