import React, { useContext } from 'react';
import { stateContext } from '../../context/appContext';

function Label({ pickSection, label, value }) {
  const { operationType } = useContext(stateContext);
  return (
    <div
      onClick={() => pickSection(label)}
      className={`label-container ${label} ${operationType === label ? 'active' : ''}`}
    >
      <div className={`label ${label}`}>
        <h4>{value}</h4>
      </div>
    </div>
  );
}

export default Label;
