import React, { useContext } from 'react';
import imageManager from '../../media/imageManager';
import { stateContext } from '../../context/appContext';

function UserLabel({ openUserMenu }) {
  const { isOnline } = useContext(stateContext);
  return (
    <div onClick={openUserMenu} className='header-content-usermenu'>
      <div className={`user-status ${isOnline ? 'online' : 'offline'}`} />
      <img src={imageManager.userMenu} alt='logo' />
    </div>
  );
}

export default UserLabel;
