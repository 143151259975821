export const getTime = date => {
  const time = new Date(date);
  return time.getHours() + ':' + ('0' + time.getMinutes()).slice(-2);
};

export const getDate = date => {
  const d = new Date(date);
  const month = ('0' + (d.getMonth() + 1)).slice(-2);
  const day = ('0' + d.getDate()).slice(-2);
  const year = d.getFullYear();
  return year + '-' + month + '-' + day;
};
