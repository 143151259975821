import React, { useContext } from 'react';
import imageManager from '../../media/imageManager';
import { dispatchContext } from '../../context/appContext';
import Button from '../utils/Button';
import getUserName from '../../helpers/getUserName';

function ConfirmModal({ cashier, onChangeAdminCredit }) {
  const { closeConfirmModal } = useContext(dispatchContext);

  return (
    <div className='modal-container'>
      <div className='overlay' />
      <div className='modal-body'>
        <div className='modal-header'>
          <div className='logo-container'>
            <div className='content-logo'>
              <img src={imageManager.logo} alt='logo' />
            </div>
          </div>
          <img
            className='close-model'
            onClick={closeConfirmModal}
            src={imageManager.close}
            alt='close'
          />
        </div>
        <div className='modal-info'>
          <div>
            Are You Sure You Want to Disable Credit for <b> {getUserName(cashier)}</b>?
          </div>
        </div>

        <div className={`modal-button`}>
          <Button
            value='Yes'
            onClick={() => {
              onChangeAdminCredit(false);
              closeConfirmModal();
            }}
          />
          <Button value='No' onClick={closeConfirmModal} />
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal;
