import React, { useContext, useEffect, useState, useCallback } from 'react';
import { stateContext } from '../../context/appContext';
import FilterControl from '../FilterControl';
import Amounts from '../AdminAmounts';
import AdminFilteredHistory from '../AdminFilteredHistory';
import { getCashierLoadUnloadTransacations } from '../../providers';
import { OPERATOR_ROLE, CASHIER_ROLE } from '../../consts';
import { toast } from 'react-toastify';
import { sortByName } from '../../helpers/sites';

function HistoryTab({ cashiers, currentCashier, onChangeCashier }) {
  const { user, siteInfo } = useContext(stateContext);
  const [inAmount, setInAmount] = useState(0);
  const [outAmount, setOutAmount] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [timeFrom, setTimeFrom] = useState(new Date().setHours(0, 0, 0, 0));
  const [timeTo, setTimeTo] = useState(Date.now());
  const [allCashiers, setCashiers] = useState(cashiers);

  // set default site id
  let site_id = user.role === CASHIER_ROLE ? siteInfo.id : 0;
  if (user.role === OPERATOR_ROLE && user.sites.length === 1) {
    site_id = user.sites[0].id;
  }
  const [siteId, setSiteId] = useState(site_id);

  const memoizedCashierLoadUnloadTransacations = useCallback(async () => {
    try {
      setTransactions([]);
      const cashierId = currentCashier ? currentCashier.id : 0;
      const res = await getCashierLoadUnloadTransacations(siteId, cashierId, timeFrom, timeTo);
      setInAmount(res.cr_turnover);
      setOutAmount(res.dr_turnover);
      if (siteId || cashierId) {
        setTransactions(res.transactions);
      }
    } catch (e) {
      toast.error(e.msg, {
        autoClose: false
      });
    }

    //fetchCashierLoadUnloadTransacations();
  }, [siteId, currentCashier, timeFrom, timeTo]);

  useEffect(() => {
    memoizedCashierLoadUnloadTransacations();
  }, [memoizedCashierLoadUnloadTransacations]);

  useEffect(() => {
    setCashiers(cashiers);
  }, [cashiers]);

  function handleChangeTimeFrom(val) {
    setTimeFrom(new Date(val).getTime());
  }
  function handleChangeTimeTo(val) {
    setTimeTo(new Date(val).getTime());
  }
  function handleChangeCashier(val) {
    onChangeCashier(val);
  }
  function handleChangeSiteId(siteId) {
    let cashiersList = [];
    onChangeCashier(null);

    if (siteId) {
      cashiers.forEach(item => {
        let key;
        if (OPERATOR_ROLE in item.all_sites) {
          key = OPERATOR_ROLE;
        } else if (CASHIER_ROLE in item.all_sites) {
          key = CASHIER_ROLE;
        }

        if (key) {
          item.all_sites[key].forEach(site => {
            if (site.id === siteId) {
              cashiersList.push(item);
            }
          });
        }
      });
      setCashiers(sortByName(cashiersList));
      setSiteId(siteId);
    } else {
      setCashiers(cashiers);
      setSiteId(0);
    }
  }
  return (
    <div className='content-container'>
      <FilterControl
        isOperator={user.role === OPERATOR_ROLE}
        handleChangeTimeFrom={handleChangeTimeFrom}
        handleChangeTimeTo={handleChangeTimeTo}
        onChangeCashier={handleChangeCashier}
        handleChangeSiteId={handleChangeSiteId}
        currentCashier={currentCashier}
        cashiers={allCashiers}
      />
      <Amounts inAmount={inAmount} outAmount={outAmount} />
      <AdminFilteredHistory
        transactions={transactions}
        hasCredit={!!currentCashier && !!currentCashier.id && !!transactions.length}
      />
    </div>
  );
}

export default HistoryTab;
