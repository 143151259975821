import React from 'react';

function HeaderTab(props) {
  const { value, active, clickHandler, icon } = props;

  return (
    <div
      className={`header-tab${active ? ' active' : ''}`}
      data-value={value}
      onClick={() => {
        clickHandler(value);
      }}
    >
      <div className='header-tab-label'>
        <img src={icon} className={`header-tab-icon ${value}`} alt='icon' />
        <span>{value}</span>
      </div>
    </div>
  );
}

export default HeaderTab;
