import React, { useContext, useState } from 'react';
import imageManager from '../../media/imageManager';
import { stateContext } from '../../context/appContext';
import ListMenuItem from '../ListMenuItem';
import Select from '../Select';
import getUserName from '../../helpers/getUserName';

const UserMenu = ({ closeUserMenu, handleChangeSite }) => {
  const { user, siteInfo, isOnline } = useContext(stateContext);
  const [isMenuOpen, setMenuOpen] = useState(false);

  function onMenuToggle() {
    setMenuOpen(!isMenuOpen);
  }

  function handleSelect(id) {
    handleChangeSite(id);
    setMenuOpen(false);
    closeUserMenu(true);
  }

  const styles = isMenuOpen
    ? { height: '455px', padding: 0, top: '-2px' }
    : { height: 'auto', padding: '6px 0' };
  return (
    <div className='user-menu-container' style={styles}>
      <div className='user-menu-body'>
        <div className='user-menu-header'>
          <div className='user-menu-name'>
            <img className='user-menu-icon' src={imageManager.userMenuWhite} alt='user' />
            <span className='name'>{getUserName(user)}</span>
          </div>
          <img
            onClick={closeUserMenu}
            className='user-menu-close'
            src={imageManager.close}
            alt='close'
          />
        </div>
        {isMenuOpen ? (
          <div className='user-menu-select'>
            <Select
              handleChange={handleSelect}
              handleMenuClose={onMenuToggle}
              menuIsOpen={true}
              value={{
                id: siteInfo.id,
                name: siteInfo.name
              }}
              options={user.sites}
            />
          </div>
        ) : (
          <div className='user-menu-items'>
            <div className='menu-item' onClick={onMenuToggle}>
              <div className={`status ${isOnline ? 'on' : 'off'}`} />
              <div className='items-header'>
                {siteInfo.id} - {siteInfo.name}
              </div>
              <i className='icon-arrow down'></i>
            </div>
            <ListMenuItem />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserMenu;
