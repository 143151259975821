import React, { useState } from 'react';
import HeaderContent from './HeaderContent';
import { CSSTransition } from 'react-transition-group';
import UserMenu from './UserMenu';
import Tabs from './Tabs';
import HistoryTab from '../HistoryTab';
import CreditTab from '../CreditTab';

function AdminForMobile({
  cashiers,
  currentCashier,
  setCurrentCashier,
  onChangeAdminCredit,
  isEnabledAdminCredit
}) {
  const [activeTab, setActiveTab] = useState('history');
  const [userMenu, setUserMenu] = useState(false);

  const openUserMenu = () => setUserMenu(true);
  const closeUserMenu = () => setUserMenu(false);

  return (
    <>
      <CSSTransition in={userMenu} classNames='user-menu' timeout={500} appear unmountOnExit>
        <UserMenu closeUserMenu={closeUserMenu} />
      </CSSTransition>
      <div className='header mobile'>
        <div className='header-container'>
          <HeaderContent
            openUserMenu={openUserMenu}
            menuIsOpen={userMenu}
            onChangeAdminCredit={onChangeAdminCredit}
            isEnabledAdminCredit={
              activeTab === 'credit' && !!currentCashier && !!currentCashier.has_credit
            }
            isVisibleSwitch={activeTab === 'credit' && !!currentCashier && !!currentCashier.id}
          />
          <Tabs onChangeActiveTab={setActiveTab} activeTab={activeTab} />
        </div>
      </div>
      {activeTab === 'history' ? (
        <HistoryTab
          cashiers={cashiers}
          currentCashier={currentCashier}
          onChangeCashier={setCurrentCashier}
          cashierId={currentCashier ? currentCashier.id : 0}
        />
      ) : (
        <CreditTab
          cashiers={cashiers}
          currentCashier={currentCashier}
          onChangeCashier={setCurrentCashier}
          isVisibleCreditSettings={isEnabledAdminCredit}
        />
      )}
    </>
  );
}

export default AdminForMobile;
