import React from 'react';
import currencyFormatter from '../../helpers/currencyFormatter';

export default function Amounts({ inAmount, outAmount }) {
  return (
    <div className='total-amounts'>
      <div className='admin-amounts'>
        <div className='admin-amount in'>
          In <br /> {currencyFormatter(inAmount)}
        </div>
        <div className='admin-amount out'>
          Out <br /> -{currencyFormatter(outAmount)}
        </div>
        <div className='admin-amount netto'>
          Netto <br /> {currencyFormatter(inAmount - outAmount)}
        </div>
      </div>
    </div>
  );
}
