import React, { useState, useContext, useEffect } from 'react';
import imageManager from '../../media/imageManager';
import { stateContext, dispatchContext } from '../../context/appContext';
import Button from '../utils/Button';
import Input from '../utils/Input';
import { CSSTransition } from 'react-transition-group';
import firstLetterToUp from '../../helpers/firstLettertoUp';
import deleteGaps from '../../helpers/deleteGapsFromString';
import currencyFormatter from '../../helpers/currencyFormatter';
import {
  TYPE_UNLOAD_FUNDS,
  TYPE_LOAD_FUNDS,
  UNIT_STATUS_HOLD,
  UNIT_STATUS_CASH_OUT,
  MAX_AMOUNT
} from '../../consts';

function CashService({ operationType, loadFundsToUnit, unLoadFundsFromUnit, getUnit }) {
  const { loadParams, cashierCredit, has_credit } = useContext(stateContext);
  const {
    setEntryParam,
    setInfoMessage,
    setCashValue,
    closeTransactionModal,
    sendTransaction,
    sendTransactionError
  } = useContext(dispatchContext);
  const [step, setStep] = useState('1');
  const [confirmCode, setCode] = useState('');
  const [balance, setBalance] = useState(0);
  const [isDisabledButton, setStatusButton] = useState(false);

  const handleClickIn = (type, value) => {
    setInfoMessage('Was Loaded Successfully');
    switch (type) {
      case 'phone':
        return cashInPhone(value);
      case 'unit':
        return cashInUnit();
      case 'token':
        return cashInToken();
      default:
        return null;
    }
  };

  const cashInUnit = () => {
    const amount = parseInt(deleteGaps(loadParams.value)) * 100;
    loadFundsToUnit(loadParams.param, amount);
  };

  const cashInToken = () => {
    setTimeout(() => sendTransaction(), 200);
  };

  const cashInPhone = value => {
    if (!value.length) {
      setInfoMessage('Link was successfully sent');
      setTimeout(() => sendTransaction(), 200);
      // send link to User
    } else {
      setTimeout(() => sendTransaction(), 200);
    }
  };

  useEffect(() => {
    const inputForNumber = document.getElementById('check_number');
    const inputForAmount = document.getElementById('check_amount');
    const inputForCode = document.getElementById('check_code');
    if (step === '1') {
      inputForNumber.focus();
    } else if (step === '2' && inputForAmount) {
      inputForAmount.focus();
    } else if (step === '3' && inputForCode) {
      inputForCode.focus();
    }
  }, [step]);

  const handleClickOut = type => {
    setInfoMessage('Was Cashed Out Successfully');
    if (type === 'unit') {
      withdrawUnit();
    } else if (type === 'phone') {
      withdrawPhone();
    } else if (type === 'token') {
      withdrawToken();
    }
  };

  const withdrawToken = () => {
    setTimeout(() => sendTransaction(), 200);
  };

  const withdrawUnit = () => {
    unLoadFundsFromUnit(loadParams.param, parseInt(balance));
  };

  const handleConfirmCode = event => {
    setCode(event.target.value);
  };

  const withdrawPhone = () => {
    sendConfirmationCode();
  };

  const checkCodeAndSend = () => {
    if (confirmCode.length !== 4) {
      return true;
    } else {
      setTimeout(() => sendTransaction(), 200);
    }
  };

  const sendConfirmationCode = () => {
    setStep('3');
  };

  const goToNextSection = async () => {
    try {
      if (validateParam(loadParams.type, loadParams.param)) {
        const res = await getUnit(loadParams.param);
        if (res) {
          switch (true) {
            case operationType === TYPE_LOAD_FUNDS &&
              res.unit.state.hopper_state === UNIT_STATUS_HOLD:
              setStep('2');
              setBalance(res.unit.fin_account.balance);
              break;

            case operationType === TYPE_UNLOAD_FUNDS &&
              res.unit.state.hopper_state === UNIT_STATUS_CASH_OUT:
              setStep('2');
              setBalance(res.unit.fin_account.balance);
              setCashValue(res.unit.fin_account.balance / 100);
              break;

            case operationType === TYPE_LOAD_FUNDS &&
              res.unit.state.hopper_state !== UNIT_STATUS_HOLD:
              sendTransactionError(`Unit ${loadParams.param} needs to be Сashed out first`);
              break;

            case operationType === TYPE_UNLOAD_FUNDS &&
              res.unit.state.hopper_state !== UNIT_STATUS_CASH_OUT:
              sendTransactionError(`Press Сashout on Unit ${loadParams.param}`);
              break;

            default:
              sendTransactionError('ERROR');
              break;
          }
        }
      }
    } catch (error) {
      sendTransactionError('Unit was not found');
    }
  };

  const setButtonName = (type, balanceValue) => {
    if (operationType === TYPE_LOAD_FUNDS) {
      switch (type) {
        case 'phone': {
          if (balanceValue.length) {
            return 'Load Credit';
          }
          return 'Send Link';
        }
        case 'unit':
          return 'Load Credit';
        default:
          return '';
      }
    }
    return loadParams.type === 'phone' ? 'Send Code' : 'Withdraw';
  };

  const validateValue = (data, type) => {
    if (
      (!balance.length && operationType === TYPE_LOAD_FUNDS && type === 'phone') ||
      (balance && operationType === TYPE_UNLOAD_FUNDS && type === 'unit')
    ) {
      return false;
    }
    const value = deleteGaps(data);
    if (validateAmount(value)) {
      return true;
    }
    let hasCredit;
    if (has_credit) {
      hasCredit = parseInt(value) > cashierCredit / 100;
    }
    if (hasCredit || !value || parseInt(value) <= 0) {
      return true;
    }
  };

  const handleBackClick = () => {
    if (step === '3') {
      setStep('2');
      setCode('');
    } else {
      setStep('1');
      setCashValue('');
    }
  };

  const setMaskForValue = inputValue => {
    const regex = /[0-9]/;
    if (regex.test(inputValue[inputValue.length - 1])) {
      const value = deleteGaps(inputValue);
      if (value.length >= 4) {
        const offset = value.length - 4 + 1;
        const str1 = value.substring(0, offset);
        const str2 = value.substring(offset);
        return `${str1} ${str2}`;
      }
      return value;
    }
    return inputValue.replace(inputValue.slice(inputValue.length - 1), '');
  };

  const handleChange = event => {
    if (event.target.validity.valid) {
      if (step === '1') {
        setEntryParam(event.target.value);
      } else {
        setCashValue(deleteGaps(event.target.value));
      }
    }
  };

  const resetValue = () => {
    if (step === '1') {
      setEntryParam('');
      document.getElementById('check_number').focus();
    } else {
      setCashValue('');
      document.getElementById('check_amount').focus();
    }
  };

  const setPlaceHolder = type => {
    if (type === 'phone') {
      return 'Phone Number';
    }
    return 'Unit';
  };

  const validateParam = (type, loginNumber) => {
    if (type === 'phone') {
      return loginNumber.length === 9;
    }
    return loginNumber.length > 0 && loginNumber.length <= 6;
  };

  const checkError = (type, loginNumber) => {
    if (type === 'phone') {
      return loginNumber.length > 9;
    }
    return loginNumber.length > 6;
  };

  const showLabel = type => {
    if (type === 'phone') {
      return 'Enter Phone Number';
    }
    return 'Enter Machine ID';
  };

  const checkAmountFieldNeeded = () => {
    if (loadParams.type === 'unit' && operationType === TYPE_LOAD_FUNDS) return true;
    if (loadParams.type === 'unit' && operationType === TYPE_UNLOAD_FUNDS) return false;
    if (loadParams.type === 'phone') return true;
  };

  const handleMainClick = () => {
    if (validateValue(loadParams.value, loadParams.type)) {
      return;
    }
    // disable a button after click
    setStatusButton(true);

    return operationType === TYPE_LOAD_FUNDS
      ? handleClickIn(loadParams.type, loadParams.value)
      : handleClickOut(loadParams.type);
  };

  const validateAmount = value => {
    const _value = deleteGaps(value);
    if (has_credit) {
      return +_value > cashierCredit / 100;
    }
    return +_value > MAX_AMOUNT;
  };

  const renderActionButton = () => {
    switch (step) {
      case '1':
        return (
          <Button
            error={!validateParam(loadParams.type, loadParams.param)}
            value={'Enter'}
            id={'action_button'}
            onClick={() => goToNextSection(loadParams.param)}
          />
        );
      case '2':
        return (
          <Button
            error={validateValue(loadParams.value, loadParams.type)}
            id={'action_button'}
            value={setButtonName(loadParams.type, loadParams.value)}
            onClick={handleMainClick}
            disabled={isDisabledButton}
          />
        );
      case '3':
        return (
          <Button
            id={'action_button'}
            error={!(confirmCode.length === 4)}
            value={'Withdraw'}
            onClick={checkCodeAndSend}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={`modal-body ${step !== '1' ? 'open' : ''} ${step === '3' ? 'confirm' : ''}`}>
      <div className='modal-header'>
        <div className='logo-container'>
          <div className='content-logo'>
            <img src={imageManager.logo} alt='logo' />
          </div>
          <span>
            {operationType === TYPE_LOAD_FUNDS
              ? `Load Credit to ${firstLetterToUp(loadParams.type)}`
              : `Withdrawal from ${firstLetterToUp(loadParams.type)}`}
          </span>
        </div>
        <img
          className='close-model'
          onClick={closeTransactionModal}
          src={imageManager.close}
          alt='close'
        />
      </div>
      <div className='modal-info'>
        <div className='information-credit'>
          <div className='credit-label'>
            <img src={imageManager.warning} alt='logo' />
            <span>Credit Remaining</span>
          </div>
          <span className='credit-value'>{has_credit && currencyFormatter(cashierCredit)}</span>
        </div>
      </div>

      <CSSTransition in={step !== '1'} appear unmountOnExit timeout={300} classNames='balance'>
        <div className='modal-info balance'>
          <div className='information-credit'>
            <div className='credit-label'>
              <img src={imageManager.balanceDark} alt='logo' />
              <span>Current Balance</span>
            </div>
            <span className='credit-value'>{currencyFormatter(balance)}</span>
          </div>
        </div>
      </CSSTransition>

      <div className={`modal-field`}>
        <div className={`${loadParams.type}-field`}>
          <label htmlFor='#check_number'>{showLabel(loadParams.type)}</label>
          <Input
            type='number'
            name='check'
            placeholder={setPlaceHolder(loadParams.type)}
            active={validateParam(loadParams.type, loadParams.param)}
            error={checkError(loadParams.type, loadParams.param)}
            id='check_number'
            resetValue={resetValue}
            onChange={handleChange}
            value={loadParams.param}
            disabled={step === '2'}
            pattern='[0-9]*'
          />
        </div>

        {checkAmountFieldNeeded() && (
          <CSSTransition in={step !== '1'} appear unmountOnExit timeout={300} classNames='balance'>
            <div className='value-fild'>
              <label htmlFor='#check_amount'>Enter Amount</label>
              <Input
                type='text'
                name='check'
                placeholder='Amount'
                active={step === '3'}
                resetValue={resetValue}
                error={validateAmount(loadParams.value)}
                id='check_amount'
                onChange={handleChange}
                value={setMaskForValue(loadParams.value)}
                disabled={step === '3'}
                pattern='[ 0-9]*'
                inputMode='numeric'
              />
            </div>
          </CSSTransition>
        )}

        <CSSTransition in={step === '3'} appear unmountOnExit timeout={300} classNames='balance'>
          <div className='code-fild'>
            <label htmlFor='#check_amount'>Confirmation Code</label>
            <Input
              type='number'
              name='password'
              placeholder='Confirmation Code'
              active={confirmCode.length === 4}
              resetValue={resetValue}
              error={confirmCode.length > 4}
              id='check_code'
              onChange={handleConfirmCode}
              icon={imageManager.lock}
              value={confirmCode}
            />
          </div>
        </CSSTransition>
      </div>
      <div className={`modal-button ${step === '3' ? 'send-code' : ''}`}>
        <CSSTransition in={step !== '1'} appear unmountOnExit timeout={300} classNames='balance'>
          <div className='button-container'>
            <button onClick={handleBackClick} className='back-button'>
              Back
            </button>
          </div>
          {/* Button with other styles. */}
          {/*<Button error={true} value='Back' onClick={handleBackClick} />*/}
        </CSSTransition>
        {renderActionButton()}
      </div>
    </div>
  );
}

export default CashService;
