import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { dispatchContext } from '../../context/appContext';
import imageManager from '../../media/imageManager';

const ListMenuItem = () => {
  const { logout } = useContext(dispatchContext);
  return (
    <>
      <Link to='/' className='menu-item'>
        <>
          <img src={imageManager.monitor} alt='icon' />
          <div className='name'>POS</div>
        </>
      </Link>
      <Link to='/admin' className='menu-item'>
        <>
          <img src={imageManager.admin} alt='icon' />
          <div className='name'>Admin</div>
        </>
      </Link>
      <div onClick={logout} className='menu-item'>
        <img src={imageManager.logout} alt='logout' />
        <div className='name'>Log Out</div>
      </div>
    </>
  );
};

export default ListMenuItem;
