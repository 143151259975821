import logo from './icons/logo.svg';
import balance from './icons/balance.svg';
import balanceDark from './icons/balance_dark.svg';
import warning from './icons/warning.svg';
import user from './icons/user.svg';
import userMenu from './icons/user-menu-icon.svg';
import userMenuWhite from './icons/user-menu-icon-white.svg';
import account from './icons/account.svg';
import accountLight from './icons/account-light.svg';
import accountWhite from './icons/account_white.svg';
import logout from './icons/logout.svg';
import close from './icons/close.svg';
import lock from './icons/lock.svg';
import error from './icons/error.svg';
import cancel from './icons/cancel.svg';
import money from './icons/money.svg';
import moneyLight from './icons/money_light.svg';
import archive from './icons/archive.svg';
import calendar from './icons/calendar.svg';
import chain from './icons/chain.svg';
import admin from './icons/admin.svg';
import monitor from './icons/monitor.svg';
import success from './icons/success.svg';
export default {
  logo,
  close,
  lock,
  balance,
  balanceDark,
  user,
  cancel,
  warning,
  account,
  accountLight,
  userMenuWhite,
  logout,
  error,
  success,
  accountWhite,
  userMenu,
  money,
  moneyLight,
  archive,
  calendar,
  chain,
  admin,
  monitor
};
