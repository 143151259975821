import React, { useMemo, memo } from 'react';

function Input({
  type,
  value,
  id,
  placeholder,
  error,
  onChange,
  active,
  name,
  onFocus,
  disabled = false,
  resetValue = null,
  pattern,
  inputMode,
  onAnimationStart
}) {
  const displayIcon = () => {
    if (name === 'loginId') {
      return (
        <svg
          className={`input-icon ${error ? 'error' : active ? 'active' : ''}`}
          xmlns='http://www.w3.org/2000/svg'
          width='24.911'
          height='24.912'
          viewBox='0 0 24.911 24.912'
        >
          <path
            id='iconmonstr-user-1_2_'
            d='M21.613 18.784c-3.57-.824-6.892-1.547-5.283-4.586 4.9-9.251 1.3-14.2-3.874-14.2C7.181 0 3.67 5.137 8.582 14.2c1.658 3.057-1.791 3.779-5.283 4.586C.109 19.521-.01 21.1 0 23.874v1.038h24.9v-1.006c.012-2.79-.1-4.382-3.3-5.123z'
            data-name='iconmonstr-user-1 (2)'
          />
        </svg>
      );
    } else if (name === 'password') {
      return (
        <svg
          className={`input-icon ${error ? 'error' : active ? 'active' : ''}`}
          xmlns='http://www.w3.org/2000/svg'
          width='19.447'
          height='25.929'
          viewBox='0 0 19.447 25.929'
        >
          <path
            id='iconmonstr-lock-1_1_'
            d='M19.206 10.8V6.482a6.482 6.482 0 0 0-12.965 0V10.8H3v15.129h19.447V10.8zm-10.8 0V6.482a4.322 4.322 0 1 1 8.643 0V10.8z'
            data-name='iconmonstr-lock-1 (1)'
            transform='translate(-3)'
          />
        </svg>
      );
    } else if (name === 'check') {
      if (active) {
        return (
          <svg
            className={`input-icon`}
            xmlns='http://www.w3.org/2000/svg'
            width='33.145'
            height='26.24'
            viewBox='0 0 33.145 26.24'
          >
            <g fill='#58caa3'>
              <path
                id='iconmonstr-check-mark-1_1_'
                d='M28.014 2L12.429 17.974l-7.3-6.92L0 16.186 12.429 28.24 33.145 7.131z'
                data-name='iconmonstr-check-mark-1 (1)'
                transform='translate(0 -2)'
              />
            </g>
          </svg>
        );
      } else if (error) {
        return (
          <svg
            onClick={resetValue}
            height='365pt'
            className={`input-icon error`}
            viewBox='0 0 365.71733 365'
            width='365pt'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g fill='#e55895'>
              <path d='m356.339844 296.347656-286.613282-286.613281c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503906-12.5 32.769532 0 45.25l286.613281 286.613282c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082032c12.523438-12.480468 12.523438-32.75.019532-45.25zm0 0' />
              <path d='m295.988281 9.734375-286.613281 286.613281c-12.5 12.5-12.5 32.769532 0 45.25l15.082031 15.082032c12.503907 12.5 32.769531 12.5 45.25 0l286.632813-286.59375c12.503906-12.5 12.503906-32.765626 0-45.246094l-15.082032-15.082032c-12.5-12.523437-32.765624-12.523437-45.269531-.023437zm0 0' />
            </g>
          </svg>
        );
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _displayIcon = useMemo(() => displayIcon, [error, active, name]);
  const plaseHolderIsValid = error ? (typeof error !== 'string' ? '' : error) : placeholder;
  return (
    <div className={`input-container${error ? ' error' : ''}`}>
      <input
        className={`input${error ? ' error' : ''}`}
        type={type}
        name={name}
        id={id}
        disabled={disabled}
        placeholder={plaseHolderIsValid}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        pattern={pattern}
        inputMode={inputMode}
        onAnimationStart={onAnimationStart}
      />
      {_displayIcon()}
    </div>
  );
}

export default memo(Input);
