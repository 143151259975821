// https://hexdocs.pm/phoenix/js/ documentation
export default class Channel {
  constructor(name, params) {
    this.name = name;
    this.params = params;
    // return this;
  }

  connect = socket => {
    this.channel = socket.channel(this.name, this.params);
    return new Promise((resolve, reject) => {
      this.channel
        .join()
        .receive('ok', props => {
          resolve({ ...this, props });
        })
        .receive('error', err => {
          reject({ err, message: `Unable to join channel ${this.name}` });
        })
        .receive('timeout', () => {
          reject({ message: `Networking issue. Still waiting... ${this.name}` });
        });
    });
  };

  on = (event, callback) => {
    this.channel.on(event, callback);
  };

  push = (command, data) => {
    return new Promise((resolve, reject) => {
      this.channel
        .push(command, data, 10000)
        .receive('ok', msg => resolve(msg))
        .receive('error', msg => reject(msg))
        .receive('timeout', () => reject({ message: 'Timeout' }));
    });
  };

  leave = () => {
    return new Promise((resolve, reject) => {
      this.channel
        .leave()
        .receive('ok', msg => resolve('msg'))
        .receive('error', msg => reject(msg))
        .receive('timeout', () => reject({ message: 'Timeout' }));
    });
  };
}
