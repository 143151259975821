import React from 'react';
import './styles/App.scss';
import Login from './pages/Login';
import Home from './pages/Home';
import Admin from './pages/Admin';
import Store from './context/AppProvider';
import ProtectedRoute from './components/utils/ProtectedRoute';
import { BrowserRouter as Routes, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <Routes>
      <Store>
        <Switch>
          <Route exact path='/login' component={Login} />
          <ProtectedRoute path='/admin' component={Admin} />
          <ProtectedRoute path='/' component={Home} />
        </Switch>
      </Store>
    </Routes>
  );
}

export default App;
