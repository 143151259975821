import React, { useState, useEffect, useContext, memo } from 'react';
import Switch from 'react-switch';
import Tab from './Tab';
import imageManager from '../../../media/imageManager';
import { stateContext } from '../../../context/appContext';
import { OPERATOR_ROLE } from '../../../consts';

function Tabs({
  currentCashier,
  activeTab,
  onChangeActiveTab,
  onChangeAdminCredit,
  isEnabledAdminCredit,
  isVisibleSwitch
}) {
  const {
    user: { role: userRole }
  } = useContext(stateContext);

  const [isEnabled, toggleAdminEnableCredit] = useState(isEnabledAdminCredit);
  useEffect(() => {
    toggleAdminEnableCredit(isEnabledAdminCredit);
  }, [currentCashier, isEnabledAdminCredit]);

  return (
    <div className='header-tabs'>
      <Tab
        value='history'
        icon={imageManager.archive}
        active={activeTab === 'history'}
        clickHandler={onChangeActiveTab}
      />
      {userRole === OPERATOR_ROLE && (
        <Tab
          value='credit'
          icon={imageManager.money}
          active={activeTab === 'credit'}
          clickHandler={onChangeActiveTab}
        />
      )}
      <div className='header-tabs-substrate' />
      {isVisibleSwitch && (
        <div className='enable-credit'>
          <div className='enable-credit-toggle'>
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              offColor='#a7aad1'
              offHandleColor='#e2e4f8'
              onColor='#666a96'
              onHandleColor='#e2e4f8'
              boxShadow='none'
              activeBoxShadow='none'
              // className='adminEnableCreditToggle'
              onChange={val => {
                if (isEnabled && !val) {
                  onChangeAdminCredit(val);
                } else {
                  toggleAdminEnableCredit(val);
                  onChangeAdminCredit(val);
                }
              }}
              checked={isEnabled}
            />
          </div>
          <div className='enable-credit-label'>
            {isEnabled ? 'Disable Credit' : 'Enable Credit'}
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(Tabs);
