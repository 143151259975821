import React, { useState, memo, useContext } from 'react';
import imageManager from '../../media/imageManager';
import HeaderMenu from './HeaderMenu';
import BalanceLabel from '../BalanceLabel';
import { stateContext } from '../../context/appContext';

function HeaderContent({ children, hasBalance = false }) {
  const { isOnline } = useContext(stateContext);
  const [menu, setMenu] = useState(false);
  const handleMenuClick = () => {
    setMenu(menu => !menu);
  };

  return (
    <div className='header-content-top'>
      <div className='header-content-logo-game'>
        <div className='logo-container'>
          <div className='content-logo'>
            <img src={imageManager.logo} alt='logo' />
          </div>
          <span>7Riches</span>
        </div>
        <div className='header-content-game'>
          {children && (
            <>
              <div className={`status ${isOnline ? 'on' : 'off'}`} />
              <div className='header-select'>{children}</div>
            </>
          )}
        </div>
      </div>
      {hasBalance && <BalanceLabel />}
      <HeaderMenu isOpen={menu} handleMenuClick={handleMenuClick} />
    </div>
  );
}

export default memo(HeaderContent);
