import React, { useEffect, memo } from 'react';

function Button({ value, onClick, error, id, disabled = false, extraClass = '', inline }) {
  function materializeEffect(event) {
    const existCircle = document.getElementsByClassName('circle')[0];
    if (existCircle) {
      existCircle.remove();
    }
    const circle = document.createElement('div');
    const x = event.layerX;
    const y = event.layerY;
    circle.classList.add('circle');
    circle.style.left = `${x}px`;
    circle.style.top = `${y}px`;
    this.appendChild(circle);
  }

  const handleEvent = e => {
    const button = document.getElementById('action_button');
    if (e.keyCode === 13 && button) {
      button.click();
    }
  };

  useEffect(() => {
    const button = document.querySelector('.button');
    if (error) {
      button.removeEventListener('click', materializeEffect);
    } else {
      window.addEventListener('keypress', handleEvent);
      button.addEventListener('click', materializeEffect);
    }

    return () => {
      window.removeEventListener('keypress', handleEvent);
      button.removeEventListener('click', materializeEffect);
    };
  }, [error]);

  return (
    <div className={inline ? 'inline-button-container' : 'button-container'}>
      <button
        disabled={disabled}
        id={id}
        className={`button ${error ? 'error' : ''} ${extraClass ? extraClass : ''}`}
        onClick={onClick}
      >
        {value}
      </button>
    </div>
  );
}

export default memo(Button);
