import React, { useContext, useEffect, useState, useCallback } from 'react';
import { stateContext } from '../../context/appContext';
import FilterControl from '../FilterControl';
import AdminFilteredCredit from '../AdminFilteredCredit';
import { getCashierCreditTransactions } from '../../providers';
import { OPERATOR_ROLE, CASHIER_ROLE } from '../../consts';
import { toast } from 'react-toastify';
import usePrevious from '../../hooks/usePrevious';
import { sortByName } from '../../helpers/sites';

function CreditTab({ cashiers, onChangeCashier, isVisibleCreditSettings, currentCashier }) {
  const { user } = useContext(stateContext);
  const [transactions, setTransactions] = useState([]);
  const [timeFrom, setTimeFrom] = useState(new Date().setHours(0, 0, 0, 0));
  const [timeTo, setTimeTo] = useState(Date.now());
  const [allCashiers, setCashiers] = useState(cashiers);
  const prevTimeTo = usePrevious(timeTo);

  const memoizedCashierCreditTransacations = useCallback(async () => {
    try {
      setTransactions([]);
      const id = currentCashier ? currentCashier.id : 0;
      if (id) {
        const timeToEnd =
          prevTimeTo === timeTo ? new Date().setMinutes(new Date().getSeconds() + 60) : timeTo;
        const res = await getCashierCreditTransactions(id, timeFrom, timeToEnd);
        setTransactions(res.transactions);
      }
    } catch (e) {
      toast.error(e.msg, {
        autoClose: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCashier, timeTo, timeFrom]);

  useEffect(() => {
    memoizedCashierCreditTransacations();
  }, [memoizedCashierCreditTransacations]);

  useEffect(() => {
    if (user.sites.length === 1) {
      handleChangeSiteId(user.sites[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangeTimeFrom(val) {
    setTimeFrom(new Date(val).getTime());
  }
  function handleChangeTimeTo(val) {
    setTimeTo(new Date(val).getTime());
  }
  function handleChangeCashier(val) {
    onChangeCashier(val);
  }
  function handleChangeSiteId(siteId) {
    let cashiersList = [];
    let cashierData;

    if (siteId) {
      cashiers.forEach(item => {
        let key;
        if (OPERATOR_ROLE in item.all_sites) {
          key = OPERATOR_ROLE;
        } else if (CASHIER_ROLE in item.all_sites) {
          key = CASHIER_ROLE;
        }

        if (key) {
          item.all_sites[key].forEach(site => {
            if (site.id === siteId) {
              if (!cashierData) {
                cashierData = item;
              }
              cashiersList.push(item);
            }
          });
        }
      });
      if (cashierData) {
        onChangeCashier(cashierData);
      } else {
        onChangeCashier(null);
      }
      setCashiers(sortByName(cashiersList));
    } else {
      setCashiers(cashiers);
      onChangeCashier(null);
    }
  }
  return (
    <div className='content-container'>
      <FilterControl
        isOperator={user.role === OPERATOR_ROLE}
        handleChangeTimeFrom={handleChangeTimeFrom}
        handleChangeTimeTo={handleChangeTimeTo}
        onChangeCashier={handleChangeCashier}
        handleChangeSiteId={handleChangeSiteId}
        cashiers={allCashiers}
        currentCashier={currentCashier}
        isVisibleCreditSettings={isVisibleCreditSettings}
      />
      <AdminFilteredCredit transactions={transactions} />
    </div>
  );
}

export default CreditTab;
