import React, { memo } from 'react';
import displayIcon from '../../helpers/displayIcon';

function MenuButton({ name, keyButton, action, theme, disabled = false }) {
  return (
    <div
      onClick={disabled ? null : () => action(name)}
      className={`menu-button ${theme} ${disabled ? 'disabled' : ''}`}
    >
      {disabled && <div className='disabled'></div>}
      <h3 className='menu-button-key'>{keyButton}</h3>
      {displayIcon(name, theme)}
      <h3 className='menu-button-name'>{name}</h3>
    </div>
  );
}

export default memo(MenuButton);
