import axios from 'axios';
import { authRefresh } from '../providers';

const client = axios.create({
  ...(process.env.NODE_ENV === 'production' && {
    baseURL: `https://${process.env.REACT_APP_API_URL}`
  })
});

client.interceptors.request.use(
  config => {
    const profile = JSON.parse(window.localStorage.getItem('profile')) || null;
    if (!config.headers.Authorization && profile) {
      const token = profile.access.token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  error => Promise.reject(error)
);

client.interceptors.response.use(
  config => {
    return config;
  },
  async error => {
    const profile = window.localStorage.getItem('profile');
    if (
      error.config &&
      error.response &&
      error.response &&
      error.response.status === 401 &&
      profile
    ) {
      const data = JSON.parse(profile);
      if (Date.now() < data.refresh.expires * 1000) {
        const response = await authRefresh(data.refresh.token);
        const newData = { ...data, ...response };
        localStorage.setItem('profile', JSON.stringify(newData));
        error.config.headers.Authorization = `Bearer ${response.access.token}`;
        return axios.request(error.config);
      } else {
        window.localStorage.removeItem('profile');
        window.location.href = '/login';
      }
    } else {
      return Promise.reject(error);
    }
  }
);

const request = async function(options) {
  const onSuccess = function(response) {
    if (!response.data.success) {
      return Promise.reject({ msg: 'ERROR' });
    }
    console.info('Request Successful!', response);
    return response.data;
  };

  const onError = function(error) {
    console.error('Request Failed:', error.config);

    if (error.response) {
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    } else {
      console.error('Error Message:', error.message);
    }
    return Promise.reject({
      msg: error.response.data.error.msg,
      code: error.response.data.error.code
    });
  };

  try {
    const response = await client(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export default request;
