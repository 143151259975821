import React, { useState, useEffect } from 'react';
import FilterInput from '../utils/FilterInput';
import CreditList from '../CreditList';
import { getDate, getTime } from '../../helpers/date';

function AdminFilteredCredit({ transactions, hasCredit }) {
  const [filteredTransactions, setFilteredTransactions] = useState(transactions);
  const [param, setParam] = useState('');

  useEffect(() => {
    setFilteredTransactions(transactions);
  }, [transactions]);

  const filterHistory = param => {
    setParam(param);
    const data = transactions.filter(item => {
      return getDate(item.timestamp).includes(param) || getTime(item.timestamp).includes(param);
    });
    setFilteredTransactions(data);
  };
  return (
    <>
      <div className='adminSearchBox'>
        <FilterInput
          placeholder='Click here to search…'
          keyButton='F3'
          type='in'
          value={param}
          changeHandler={filterHistory}
          inputType={'text'}
        />
      </div>
      <CreditList transactions={filteredTransactions} hasCredit={hasCredit} />
    </>
  );
}

export default AdminFilteredCredit;
