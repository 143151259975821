export default user => {
  if (user.name) {
    return user.name;
  } else if (user.login) {
    return user.login;
  } else if (user.email) {
    return user.email;
  } else {
    return '';
  }
};
