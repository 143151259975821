import React, { useState, useEffect } from 'react';
import imageManager from '../../media/imageManager';
import UserLabel from './UserLabel';
import screenfull from 'screenfull';
import Switch from 'react-switch';

function HeaderContent({
  openUserMenu,
  menuIsOpen,
  currentCashier,
  onChangeAdminCredit,
  isEnabledAdminCredit,
  isVisibleSwitch
}) {
  const [isEnabled, toggleAdminEnableCredit] = useState(isEnabledAdminCredit);
  useEffect(() => {
    toggleAdminEnableCredit(isEnabledAdminCredit);
  }, [currentCashier, isEnabledAdminCredit]);

  function launchFullScreen() {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  }
  return (
    <div className='header-content-top'>
      <div className='header-content-logo-game'>
        <div className='logo-container'>
          <div className='content-logo' onClick={launchFullScreen}>
            <img src={imageManager.logo} alt='logo' />
          </div>
        </div>
      </div>

      {isVisibleSwitch && (
        <div className='enable-credit'>
          <div className='enable-credit-toggle'>
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              offColor='#a7aad1'
              offHandleColor='#e2e4f8'
              onColor='#666a96'
              onHandleColor='#e2e4f8'
              boxShadow='none'
              activeBoxShadow='none'
              height={20}
              width={32}
              onChange={val => {
                if (isEnabled && !val) {
                  onChangeAdminCredit(val);
                } else {
                  toggleAdminEnableCredit(val);
                  onChangeAdminCredit(val);
                }
              }}
              checked={isEnabled}
            />
          </div>
          <div className='enable-credit-label'>
            {isEnabled ? 'Disable Credit' : 'Enable Credit'}
          </div>
        </div>
      )}

      <UserLabel openUserMenu={openUserMenu} menuIsOpen={menuIsOpen} />
    </div>
  );
}

export default HeaderContent;
