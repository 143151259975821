import React, { useContext, useCallback, useEffect, useRef, memo } from 'react';
import Search from '../../media/icons/search.svg';
import { stateContext } from './../../context/appContext';
// import { dispatchContext } from '../../../context/appContext';

function FilterInput({
  placeholder,
  type,
  value,
  keyButton,
  changeHandler,
  inputType,
  inputPattern
}) {
  const { isMobile } = useContext(stateContext);

  const inputEl = useRef(null);

  const setKeyDown = useCallback(
    event => {
      if (event.code === keyButton) {
        event.preventDefault();
        inputEl.current.focus();
      }
    },
    [keyButton]
  );

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener('keydown', setKeyDown, false);
    }
    return () => window.removeEventListener('keydown', setKeyDown, false);
  }, [isMobile, setKeyDown]);

  return (
    <div className='filter-input-container'>
      {!isMobile && <span className={`input-key ${type}`}>{keyButton}</span>}
      <input
        placeholder={placeholder}
        onChange={event => {
          changeHandler(event.target.value);
        }}
        ref={inputEl}
        className='filter-input'
        type={inputType}
        pattern={inputPattern}
        value={value}
      />
      <img className='filter-input-icon' src={Search} alt='search' />
    </div>
  );
}

export default memo(FilterInput);
