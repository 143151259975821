import React, { useState, useEffect } from 'react';
import FilterInput from '../utils/FilterInput';
import HistoryList from '../HistoryList';

function AdminFilteredHistory({ transactions, hasCredit }) {
  const [filteredTransactions, setFilteredTransactions] = useState(transactions);
  const [param, setParam] = useState('');

  useEffect(() => {
    setFilteredTransactions(transactions);
  }, [transactions]);

  const filterHistory = param => {
    setParam(param);
    if (param) {
      let data = transactions.filter(item => item.account.entity.label === param);
      if (!data.length) {
        data = transactions.filter(item => item.account.entity.label.includes(param));
      }
      setFilteredTransactions(data);
    } else {
      setFilteredTransactions(transactions);
    }
  };
  return (
    <>
      <div className='adminSearchBox'>
        <FilterInput
          placeholder='Type «Unit/Phone» to search…'
          keyButton='F3'
          type='in'
          value={param}
          changeHandler={filterHistory}
          inputType='number'
          inputPattern='[0-9]*'
        />
      </div>
      <HistoryList transactions={filteredTransactions} hasCredit={hasCredit} />
    </>
  );
}

export default AdminFilteredHistory;
