import React, { useContext } from 'react';
import { dispatchContext, stateContext } from '../../context/appContext';
import imageManager from '../../media/imageManager';
import currencyFormatter from '../../helpers/currencyFormatter';
import { TYPE_LOAD_FUNDS } from '../../consts';

const InfoModal = () => {
  const { closeInfoModal } = useContext(dispatchContext);
  const { transactionError, infoMessage, loadParams, operationType } = useContext(stateContext);
  const statusImage = transactionError ? imageManager.error : imageManager.success;
  const description = transactionError ? transactionError : infoMessage;

  return (
    <div className='info-modal'>
      <div className='overlay' onClick={closeInfoModal} />
      <div className='info-modal-body'>
        <div className='info-modal-container'>
          <div className='info-modal-logo'>
            <img src={imageManager.logo} alt='logo' />
          </div>
          <div className='info-modal-close'>
            <img onClick={closeInfoModal} src={imageManager.close} alt='close' />
          </div>
        </div>

        <div className='info-modal-content'>
          <div className={`info-modal-status-image ${transactionError ? 'error' : 'success'}`}>
            <img src={statusImage} alt='status' />
          </div>
          <div className={`info-modal-description ${transactionError ? 'error' : 'success'}`}>
            {loadParams.value && (
              <span className={operationType === TYPE_LOAD_FUNDS ? 'green-text' : 'red-text'}>
                {currencyFormatter(loadParams.value * 100)}
              </span>
            )}
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
