import React, { useContext } from 'react';
import { stateContext, dispatchContext } from '../../context/appContext';
import CashService from './CashService';

function RenderModals({ loadFundsToUnit, unLoadFundsFromUnit, getUnit }) {
  const { operationType } = useContext(stateContext);
  const { closeTransactionModal } = useContext(dispatchContext);

  return (
    <div className='modal-container'>
      <div onClick={closeTransactionModal} className='overlay' />

      <CashService
        operationType={operationType}
        labels={['Load Credit to Phone', 'Withdrawal from Phone']}
        loadFundsToUnit={loadFundsToUnit}
        unLoadFundsFromUnit={unLoadFundsFromUnit}
        getUnit={getUnit}
      />
    </div>
  );
}

export default RenderModals;
