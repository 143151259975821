import React, { useEffect, useContext, useState, useRef, useMemo } from 'react';
import Menu from '../../components/Menu';
import { dispatchContext, stateContext, socketContext } from '../../context/appContext';
import Header from './Header';
import ModalWindow from '../../components/Modals';
import InfoModal from '../../components/Modals/InfoModal';
import HomeForMobile from '../../components/HomeForMobile';
import { CSSTransition } from 'react-transition-group';
import Channel from '../../helpers/Channel';
import {
  SOCKET_GET_TRANSACTIONS,
  SOCKET_CREDIT_UNIT,
  SOCKET_DEBIT_UNIT,
  SOCKET_EVENT_POS_STATE_UPDATE,
  SOCKET_EVENT_TRANSACTIONS,
  SOCKET_GET_UNIT,
  TYPE_UNLOAD_FUNDS,
  TYPE_LOAD_FUNDS
} from '../../consts';
import { toast } from 'react-toastify';

function Home() {
  let channel = useRef(null);
  const {
    isMobile,
    modalIsOpen,
    infoModal,
    siteInfo,
    loadedFundsTransactions,
    cashOutTransaction
  } = useContext(stateContext);
  const {
    handleButtonCLick,
    setCashierCredit,
    setTransactions,
    updateTransactions,
    sendTransactionSuccess,
    sendTransactionError,
    setSiteInfo,
    setStatusChannel,
    closeTransactionModal
  } = useContext(dispatchContext);
  const { socket } = useContext(socketContext);
  const [siteId, setSiteId] = useState(siteInfo.id);
  useEffect(() => {
    (async () => {
      try {
        channel.current = await new Channel('pos', {
          site_id: siteId
        }).connect(socket);
        setStatusChannel(true);

        setCashierCredit(
          channel.current.props.cashier.cashier_credit,
          channel.current.props.cashier.has_credit
        );
        // event pos_state_updated
        channel.current.on(SOCKET_EVENT_POS_STATE_UPDATE, props => {
          setCashierCredit(props.cashier.cashier_credit, props.cashier.has_credit);
        });
        // event transaction
        channel.current.on(SOCKET_EVENT_TRANSACTIONS, transaction => {
          updateTransactions(transaction);
        });
        // get transactions
        const data = await channel.current.push(SOCKET_GET_TRANSACTIONS, {});
        setTransactions(data.transactions);
      } catch (e) {
        toast.error(e.err.msg, {
          autoClose: false
        });
        setStatusChannel(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  useEffect(() => {
    const handleKeydown = key => {
      switch (key) {
        case 'F1':
          return handleButtonCLick('phone', TYPE_LOAD_FUNDS);
        case 'F2':
          return handleButtonCLick('unit', TYPE_LOAD_FUNDS);
        // case 'F3' : return handleButtonCLick('token', TYPE_LOAD_FUNDS);
        case 'F4':
          return handleButtonCLick('phone', TYPE_UNLOAD_FUNDS);
        case 'F5':
          return handleButtonCLick('unit', TYPE_UNLOAD_FUNDS);
        // case 'F6' : return handleButtonCLick('token', TYPE_UNLOAD_FUNDS);
        case 'F7':
          return document.getElementById('filter-input-load_funds').focus();
        case 'F8':
          return document.getElementById('filter-input-unload_funds').focus();
        case 'Escape':
          return closeTransactionModal();
        default:
          return null;
      }
    };

    const setKeyDown = event => {
      if (
        //event.code === 'F1' ||
        event.code === 'F2' ||
        //event.code === 'F3' ||
        //event.code === 'F4' ||
        event.code === 'F5' ||
        //event.code === 'F6' ||
        event.code === 'F7' ||
        event.code === 'F8' ||
        event.key === 'Escape'
      ) {
        event.preventDefault();
        handleKeydown(event.code);
      }
      if (infoModal && !modalIsOpen) {
        return closeTransactionModal();
      }
    };
    if (!isMobile) {
      window.addEventListener('keydown', setKeyDown, false);
    }
    return () => {
      window.removeEventListener('keydown', setKeyDown, false);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoModal, modalIsOpen]);

  // Handling Internet Disconnection
  useEffect(() => {
    window.addEventListener('online', () => setStatusChannel(true));
    window.addEventListener('offline', () => setStatusChannel(false));
    return () => {
      window.removeEventListener('online', () => setStatusChannel(true));
      window.removeEventListener('offline', () => setStatusChannel(false));
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangeSite = async data => {
    try {
      if (channel.current) {
        await channel.current.leave();
        setSiteId(data.id);
        setSiteInfo({ id: data.id, name: data.name });
      }
    } catch (e) {
      toast.error(e.err.msg, {
        autoClose: false
      });
    }
  };
  const handleLoadFundsToUnit = async (label, amount) => {
    try {
      if (channel.current) {
        await channel.current.push(SOCKET_CREDIT_UNIT, { label, amount });
        sendTransactionSuccess();
      }
    } catch (e) {
      sendTransactionError(e.err.msg);
    }
  };
  const handleUnLoadFundsFromUnit = async (label, amount) => {
    try {
      if (channel.current) {
        await channel.current.push(SOCKET_DEBIT_UNIT, { label, amount });
        sendTransactionSuccess();
      }
    } catch (e) {
      sendTransactionError(e.err.msg);
    }
  };
  const handleGetUnit = label => {
    return channel.current.push(SOCKET_GET_UNIT, { label });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoHeader = useMemo(() => <Header handleChangeSite={handleChangeSite} />, [channel]);

  return (
    <div className={`app-container${isMobile ? ' mobile' : ''}`}>
      <CSSTransition in={modalIsOpen} classNames='modal' timeout={500} appear unmountOnExit>
        <ModalWindow
          loadFundsToUnit={handleLoadFundsToUnit}
          unLoadFundsFromUnit={handleUnLoadFundsFromUnit}
          getUnit={handleGetUnit}
        />
      </CSSTransition>
      <CSSTransition in={infoModal} classNames='modal' timeout={500} appear unmountOnExit>
        <InfoModal />
      </CSSTransition>
      {isMobile ? (
        <HomeForMobile handleChangeSite={handleChangeSite} />
      ) : (
        <>
          <div className='header'>{memoHeader}</div>
          <div className='content-container'>
            <div className='left'>
              <Menu
                transactions={loadedFundsTransactions}
                keyBoard={['F1', 'F2', 'F3', 'F7']}
                type={TYPE_LOAD_FUNDS}
              />
            </div>
            <div className='right'>
              <Menu
                transactions={cashOutTransaction}
                keyBoard={['F4', 'F5', 'F6', 'F8']}
                type={TYPE_UNLOAD_FUNDS}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Home;
