import { createContext } from 'react';
import { TYPE_LOAD_FUNDS } from '../consts';

export const stateContext = createContext();
export const dispatchContext = createContext();
export const socketContext = createContext();

export const State = stateContext.Provider;
export const Dispatch = dispatchContext.Provider;
export const SocketContext = socketContext.Provider;
export const initialState = {
  isFetching: true,
  isAuth: false,
  error: '',
  transactionError: '',
  inSearch: '',
  isMobile: false,
  infoModal: false,
  isOnline: true,
  outSearch: '',
  operationType: TYPE_LOAD_FUNDS,
  infoMessage: '',
  loadParams: {
    type: '', // phone || unit || token || : String
    param: '',
    value: ''
  },
  modalIsOpen: false,
  increaseCreditModalIsOpen: false,
  decreaseCreditModalIsOpen: false,
  confirmModalIsOpen: false,
  history: [],
  cashierCredit: 0,
  has_credit: false,
  user: {},
  cashOutTransaction: [],
  loadedFundsTransactions: [],
  siteInfo: {}
};
