import React, { useContext } from 'react';
import Button from '../utils/Button';
import { dispatchContext, stateContext } from '../../context/appContext';
import currencyFormatter from '../../helpers/currencyFormatter';
import imageManager from '../../media/imageManager';

export default function CreditSettings({ currentCashier }) {
  const { showIncreaseCreditModal, showDecreaseCreditModal } = useContext(dispatchContext);
  const { isMobile } = useContext(stateContext);

  return (
    <div className='credit-settings'>
      <div className='credit-value'>
        <div className='label'>
          {isMobile && <img src={imageManager.moneyLight} alt='icon' />}
          <span>Credit Remaining</span>
        </div>
        <div className='value'>
          {currentCashier && currentCashier.cashier_credit
            ? currencyFormatter(currentCashier.cashier_credit)
            : 'R0.00'}
        </div>
      </div>
      <Button
        value='Increase Credit'
        extraClass={`featured${!isMobile ? ' dependedOnVW' : ''}`}
        inline
        onClick={() => {
          showIncreaseCreditModal();
        }}
      />
      <Button
        value='Decrease Credit'
        extraClass={`featured alt${!isMobile ? ' dependedOnVW' : ''} ${
          currentCashier && !currentCashier.cashier_credit ? 'disabled' : ''
        }`}
        inline
        onClick={() => {
          showDecreaseCreditModal();
        }}
        disabled={currentCashier && !currentCashier.cashier_credit}
      />
    </div>
  );
}
