import React from 'react';
import displayIcon from '../../helpers/displayIcon';
import { getDate, getTime } from '../../helpers/date';
import currencyFormatter from '../../helpers/currencyFormatter';

function HistoryItem({ date, type, amount, theme, label, cashier_credit, hasCredit }) {
  return (
    <tr className='history-item'>
      <td className='history-date'>{getDate(date)}</td>
      <td className='history-time'>{getTime(date)}</td>
      <td className='history-type'>{displayIcon(type, theme)}</td>
      <td className='history-type'>{label}</td>
      <td className={`history-value ${theme}`}>{currencyFormatter(amount)}</td>
      {hasCredit && (
        <td className='history-value history-value-credit'>{currencyFormatter(cashier_credit)}</td>
      )}
    </tr>
  );
}

export default HistoryItem;
