// Login Types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const ERROR_NULL = 'ERROR_NULL';
export const LOGOUT = 'LOGOUT';
export const IS_AUNTIFICATED = 'IS_AUNTIFICATED';
export const GET_HISTORY = 'GET_HISTORY';

// FETCH
export const CONNECT_TO_POS = 'CONNECT';
export const CONNECT_ERROR = 'FETSCH_ERROR';
export const CONNECT_SUCCESS = 'FETSCH_SUCCESS';

//FILTER
export const IN_FILTERED_VALUE = 'IN_FILTERED_VALUE';
export const OUT_FILTERED_VALUE = 'OUT_FILTERED_VALUE';

// PRESS BUTTON
export const PRESS_BUTTON = 'PRESS_BUTTON';
export const SET_OPERATION_TYPE = 'SET_OPERATION_TYPE';

// MODALS
export const OPEN_MODAL_WINDOW = 'OPEN_MODAL_WINDOW';
export const CLOSE_MODAL_WINDOW = 'CLOSE_MODAL_WINDOW';
export const CHANGE_MODAL_PARAM = 'CHANGE_PHONE_MODAL';
export const CHANGE_MODAL_VALUE = 'CHANGE_MODAL_VALUE';
export const OPEN_INFO_MODAL = 'OPEN_INFO_MODAL';
export const CLOSE_INFO_MODAL = 'CLOSE_INFO_MODAL';
export const TRANSACTION_ERROR = 'TRANSACTION_ERROR';
export const SET_INFO_MESSAGE = 'SET_INFO_MESSAGE';

// SET APP for MOBILE DEVICE

export const SET_TO_MOBILE = 'SET_TO_MOBILE';
export const SET_TO_WEB = 'SET_TO_WEB';

// ADMIN TABS
export const SHOW_INCREASE_CREDIT_MODAL = 'SHOW_INCREASE_CREDIT_MODAL';
export const CLOSE_INCREASE_CREDIT_MODAL = 'CLOSE_INCREASE_CREDIT_MODAL';
export const OPEN_CREDIT_TRANSACTION_NOTIFICATION_MODAL =
  'OPEN_CREDIT_TRANSACTION_NOTIFICATION_MODAL';
export const SHOW_DECREASE_CREDIT_MODAL = 'SHOW_DECREASE_CREDIT_MODAL';
export const CLOSE_DECREASE_CREDIT_MODAL = 'CLOSE_DECREASE_CREDIT_MODAL';

export const SET_CASHIER_CREDIT = 'SET_CASHIER_CREDIT';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const UPDATE_TRANSACTIONS = 'UPDATE_TRANSACTIONS';
export const SET_SITE_INFO = 'SET_SITE_INFO';
export const SET_STATUS_CHANNEL = 'SET_STATUS_CHANNEL';
export const SET_FETCHING_STATUS = 'SET_FETCHING_STATUS';

export const SHOW_CONFIRM_MODAL = 'SHOW_CONFIRM_MODAL';
export const CLOSE_CONFIRM_MODAL = 'CLOSE_CONFIRM_MODAL';
