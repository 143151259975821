import React from 'react';
import HistoryItem from './HistoryItem';

const History = ({ theme, transactions }) => {
  const renderHistory = data => {
    return data.map(item => (
      <HistoryItem
        key={item.timestamp}
        type={item.account.type}
        theme={theme}
        date={item.timestamp}
        amount={item.amount}
        label={item.account.entity.label}
      />
    ));
  };

  return <div className='history-container'>{renderHistory(transactions)}</div>;
};

export default History;
